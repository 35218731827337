<script setup>
  import { computed, onMounted } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useLocaleStore } from '@/stores/LocaleStore';
  import * as localeConfig from '@/configs/locale-config';
  import { storeToRefs } from 'pinia';

  const locales = localeConfig.LOCALES;

  const localeStore = useLocaleStore();
  const { getLocale, getMainLocale } = storeToRefs(localeStore);

  const router = useRouter();
  const route = useRoute();

  const languageList = computed(() => {
    let list = [];

    Object.entries(locales).map(([key, value]) => {
      list.push({
        locale: key,
        ...value.content,
      });
    });

    return list;
  });

  // Setting selected based on current locale
  const isSelected = computed(() =>
    languageList.value.find((item) => item.locale === getMainLocale.value),
  );

  // Setting the locale on mounted
  onMounted(() => {
    replaceRouter(getLocale.value);
  });

  // Replacing the router to add the chosen/current locale
  function replaceRouter(locale) {
    router.replace({
      name: route.name,
      params: { locale: locale },
      query: route.query,
    });
  }

  // Adding active class to current locale
  function isActive(lang) {
    return isSelected.value.locale === lang.locale ? 'active-lang' : '';
  }

  function changeLanguage(chosen) {
    replaceRouter(chosen.locale);
  }
</script>

<template>
  <div class="dropdown language-dropdown">
    <button
      class="language-dropdown-button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <icon-globe />
      {{ $translation(isSelected.selected.key, isSelected.selected.default) }}
      <span class="visually-hidden">
        <!-- Whitespace necessary to ensure proper spacing for screen readers -->
        &nbsp;
        {{ $translation('aria_label_icon_globe', 'Language') }}
      </span>
    </button>

    <ul class="dropdown-menu language-dropdown-menu">
      <li
        class="menu-item"
        v-for="lang in languageList"
        :key="`${lang.locale}-lang`"
      >
        <button
          @click="changeLanguage(lang)"
          class="btn lang-btn"
          :class="[isActive(lang)]"
        >
          {{ $translation(lang.selection.key, lang.selection.default) }}
          <span v-if="isActive(lang)" class="visually-hidden">
            <!-- Whitespace necessary to ensure proper spacing for screen readers -->
            &nbsp;
            {{
              $translation('aria_label_language_select', 'Selected Language')
            }}
          </span>
        </button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  .language-dropdown {
    .language-dropdown-button {
      display: flex;
      flex-direction: row;
      gap: 6px;
      align-items: center;
      color: $quaternary-color;
      border: none;
      background-color: transparent;
      width: 100%;
      padding: 0;
      font-size: 0.875em;

      @include md-breakpoint {
        width: max-content;
      }

      &:focus {
        outline: none;
      }

      &:focus-visible {
        outline: $primary-color auto 1px;
        outline-offset: 2px;
        text-decoration: underline;
      }

      &:hover,
      &:active {
        text-decoration: underline;
        background-color: transparent;
      }

      &::after {
        content: none;
      }
    }

    .language-dropdown-menu {
      padding: 0;
      border-radius: 0;

      .menu-item {
        .lang-btn {
          background: $quaternary-color;
          padding-block: 15px;
          text-align: start;
          border-radius: 0;
          max-height: max-content;
          min-width: 300px;
          font-size: 1em;
          font-family: $font-regular-text;
          border-bottom: 1px solid $border-color;

          &:hover,
          &:focus-visible {
            background: $secondary-color;
            color: $quaternary-color;
            border-bottom: 1px solid $secondary-color;
          }

          &.active-lang {
            font-family: $font-bold-text;
            color: $duodecenary-color;

            &:hover,
            &:focus-visible {
              color: $quaternary-color;
            }
          }
        }
      }
    }
  }
</style>
