import { vueCreateRouter } from './vue-router.js';
import attachPerRouteNavigationGuards from './attach-per-route-navigation-guards.js';
import attachGlobalNavigationGuards from './attach-global-navigation-guards.js';

/**
 * Creates a configured Vue Router instance with the ability to attach navigation guards.
 * @param {object} options - Options for creating the Vue Router instance.
 * @returns {object} Router - The configured Vue Router instance.
 */
const createRouter = (options = {}) => {
  // Attach per route navigation guards to route.
  options.routes = attachPerRouteNavigationGuards(options);

  // Create vue router.
  let vueRouter = vueCreateRouter(options);

  // Attach global navigation guards.
  vueRouter = attachGlobalNavigationGuards(vueRouter, options);

  return vueRouter;
};

export default createRouter;
