import AuthTokenService from '../services/auth-token-service.js';
import VerifyTokenService from '../services/verify-token-service.js';
import GetProfileService from '../services/get-profile-service.js';
import SignOutService from '../services/sign-out-service.js';
import AddCardService from '../services/add-card-service.js';
import GetTranslationsService from '../services/get-translations-service.js';
import GetCardsService from '../services/get-cards-service.js';
import DeleteCardService from '../services/delete-card-service.js';
import LookUpAuthenticateService from '../services/look-up-authenticate-service.js';
import ValidateAuthService from '../services/validate-auth-service.js';
import GetIssuerCountriesService from '../services/get-issuer-countries-service.js';

class ServiceProvider {
  // Return an array of service classes.
  getServices() {
    return [
      AuthTokenService,
      VerifyTokenService,
      GetProfileService,
      SignOutService,
      AddCardService,
      GetTranslationsService,
      GetCardsService,
      DeleteCardService,
      LookUpAuthenticateService,
      ValidateAuthService,
      GetIssuerCountriesService,
    ];
  }
}

export default ServiceProvider;
