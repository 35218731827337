<script setup></script>

<template>
  <div class="alert-notification">
    <div class="icon-wrapper">
      <slot name="icon">
        <icon-bell class="alert-icon" />
      </slot>
    </div>
    <p class="alert-label"><slot /></p>
  </div>
</template>

<style lang="scss" scoped>
  .alert-notification {
    background-color: $septenary-color;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 4px;
    padding-inline: 16px;
    padding-block: 12px;
    margin-block-end: 24px;

    .alert-label {
      max-width: 976px;
      margin: 0;
      font-size: 0.875em;
      line-height: 20px;
      font-family: $font-regular-heading;
    }

    .icon-wrapper {
      width: 24px;
      height: 24px;
      .alert-icon {
        color: $octonary-color;
      }
    }
  }
</style>
