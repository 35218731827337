<script setup></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    fill="currentColor"
    aria-hidden="true"
  >
    <title>
      {{ $translation('aria_label_icon_trashcan', 'Trashcan') }}
    </title>
    <g stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
      <rect
        fill-opacity="0"
        fill="#FFFFFF"
        x="0"
        y="0"
        width="24"
        height="24"
      />
      <path
        d="M17.331125,9.69489243 L16.7897248,19.169916 L7.2102812,19.169916 L6.668861,9.69489243 C6.63256099,9.05977219 6.10696079,8.56335201 5.47082055,8.56335201 L5.31310049,8.56335201 C5.19816045,8.56335201 5.10688042,8.66001204 5.11344042,8.77475209 L5.76766066,20.2242364 C5.78580067,20.5417965 6.04862077,20.7899966 6.36668089,20.7899966 L17.6333051,20.7899966 C17.9513852,20.7899966 18.2141853,20.5417965 18.2323253,20.2242364 L18.8865656,8.77475209 C18.8931256,8.66001204 18.8018256,8.56335201 18.6868855,8.56335201 L18.5291654,8.56335201 C17.8930252,8.56335201 17.367425,9.05977219 17.331125,9.69489243 L17.331125,9.69489243 Z M9.50000206,5.70227093 L14.5000021,5.70227093 L14.5000021,4.71019056 L9.50000206,4.71019056 L9.50000206,5.70227093 Z M19.8000059,5.70227093 L16,5.70227093 L16,3.81007023 C16,3.4786701 15.7313444,3.20999 15.3999243,3.20999 L8.60006173,3.20999 C8.2686616,3.20999 8,3.4786701 8,3.81007023 L8,5.70227093 L4.20000008,5.70227093 C4.08954003,5.70227093 4,5.79181097 4,5.90227101 L4,7.00245142 C4,7.11291146 4.08954003,7.2024515 4.20000008,7.2024515 L8,7.2024515 L8,7.2104915 L16,7.2104915 L16,7.2024515 L19.8000059,7.2024515 C19.910446,7.2024515 20,7.11291146 20,7.00245142 L20,5.90227101 C20,5.79181097 19.910446,5.70227093 19.8000059,5.70227093 L19.8000059,5.70227093 Z M8.99984187,11.337313 L8.89596184,8.99963217 L10.3959618,8.99963217 L10.7959625,18.0007355 L9.29596199,18.0007355 L8.99984187,11.337313 L8.99984187,11.337313 Z M14.7040435,18.0007355 L13.2040435,18.0007355 L13.6040436,8.99963217 L15.1040442,8.99963217 L14.7040435,18.0007355 Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped></style>
