<script setup>
  import { computed, inject } from 'vue';

  const translations = inject('translations');

  const props = defineProps({
    title: String,
  });

  const modalTitle = computed(() => {
    return props.title
      ? translations.getOne(props.title, 'Loading, please wait...')
      : translations.getOne(
          'the_modal_loader_default_title',
          'Loading, please wait...',
        );
  });
</script>

<template>
  <base-modal
    id="loader-modal"
    :aria-label="$translation('aria_label_the_modal_loader', 'Loading modal')"
    class="modal-loader"
    :fade="false"
    fullscreen-modal
  >
    <template v-slot:custom-content>
      <div class="modal-content loader-modal-content">
        <icon-spinner class="loader" />
        <h1 id="loader-modal-title" class="loader-text">
          {{ modalTitle }}
        </h1>
      </div>
    </template>
  </base-modal>
</template>

<style lang="scss" scoped>
  .modal-loader {
    :deep(.base-modal-dialog) {
      background-color: $duodecenary-color;
      opacity: 70%;

      .loader-modal-content {
        position: relative;
        background-color: $duodecenary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $quaternary-color;

        .loader {
          .icon {
            top: 42%;
          }
        }

        .loader-text {
          margin-block-start: 15px;
          font-size: 1.125em;
          line-height: 28px;
          color: $quaternary-color;
          letter-spacing: 0;

          @include md-breakpoint {
            margin-block-start: 56px;
          }

          @include lg-breakpoint {
            margin-block-start: 60px;
            font-size: 1.375em;
            line-height: 32px;
          }
        }
      }
    }
  }
</style>
