import { reactive } from 'vue';

export function useModalQueue() {
  const state = reactive({
    queue: [], // Queue to handle multiple modals.
  });

  /**
   * Adds a modal to the queue.
   */
  function enqueueModal(modalComponent, options) {
    state.queue.push({ modalComponent, options });
  }

  /**
   * Removes the first modal from the queue and returns it.
   * Returns null if the queue is empty.
   */
  function dequeueModal() {
    if (state.queue.length === 0) {
      return null;
    }

    return state.queue.shift();
  }

  /**
   * Clears the queue of modals that are waiting to be displayed.
   */
  function clearQueue() {
    state.queue.splice(0, state.queue.length);
  }

  return {
    enqueueModal,
    dequeueModal,
    clearQueue,
    queue: state.queue,
  };
}
