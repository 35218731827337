<script setup>
  import { computed, inject, ref, watchEffect } from 'vue';
  import { useMainStore } from '@/stores/MainStore';

  const mainStore = useMainStore();

  const props = defineProps({
    countryMsg: {
      type: String,
      default: null,
    },
    validState: {
      type: Boolean,
      default: false,
    },
  });

  const translations = inject('translations');

  const emit = defineEmits(['update:validState']);

  const selectedCountry = ref(null);
  const inputErrorMsg = ref(null);
  const isCountryValidState = ref(false);

  const options = computed(() => {
    return mainStore.getIssuerCountries.map((option) => {
      return {
        label: getCountrySelectOptionTranslation(option.code, option.name),
        id: option.id,
        code: option.code,
      };
    });
  });

  const message = computed(() =>
    props.countryMsg ? props.countryMsg : inputErrorMsg.value,
  );

  const stateMsg = computed(() =>
    message.value
      ? translations.getOne(
          message.value,
          'The issuing country is invalid/required',
        )
      : null,
  );

  const hasInputError = computed(
    () => !!props.countryMsg || !!inputErrorMsg.value,
  );

  const inputStateSuccess = computed(
    () =>
      (props.validState || isCountryValidState.value) && !hasInputError.value,
  );

  const inputStateError = computed(
    () =>
      (!props.validState || !isCountryValidState.value) && hasInputError.value,
  );

  const isSelectedCountryAvailable = computed(() =>
    mainStore.getIssuerCountries.find(
      (country) => country.id === selectedCountry.value.id,
    ),
  );

  watchEffect(() => {
    if (selectedCountry.value === null) {
      isCountryValidState.value = false;
    }

    emit('update:validState', isCountryValidState.value);
  });

  async function updateCountryOnInput() {
    // Resets.
    isCountryValidState.value = false;
    inputErrorMsg.value = null;

    // invalid
    if (!isSelectedCountryAvailable.value) {
      inputErrorMsg.value = 'issuer_country_error_invalid';
      return;
    }

    isCountryValidState.value = true;
  }

  function getCountrySelectOptionTranslation(code, name) {
    const isDefault = code === null ? 'all' : code;
    const countryKey = `the_select_issuer_country_option_${isDefault}`;

    return translations.has(countryKey)
      ? translations.getOne(countryKey, name)
      : name;
  }
</script>

<template>
  <base-floating-select
    id="select-card-issuing-country"
    :label="
      $translation(
        'input_select_card_issuer_country_label',
        'Card issuing country',
      )
    "
    :options="options"
    v-model="selectedCountry"
    required
    @on-select="updateCountryOnInput"
    :state-msg="stateMsg"
    :input-state-error="inputStateError"
    :input-state-success="inputStateSuccess"
    :aria-label="
      $translation('aria_label_select_issuer_country', 'Select issuing country')
    "
    v-slot="{ selected }"
  >
    {{ getCountrySelectOptionTranslation(selected.code, selected.label) }}
  </base-floating-select>
</template>

<style lang="scss" scoped></style>
