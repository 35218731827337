import { BASE_URL, BASE_URL_CDE, MOCKS } from '@/configs/api-config.js';
import { SHOW_STATIC_TEXT } from '@/configs/translations-config.js';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { creditCardDetector, getSchemes } from 'schemes';
import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist';
import translations from 'vue-translations';
import { createApi } from 'api';
import loaderHandler from 'vue-loader-handler';
import modalManager from 'vue-modal-manager';
import { createHead } from '@vueuse/head';
import Dropdown from 'primevue/dropdown';
import PrimeVue from 'primevue/config';

// Needed for Bootstrap
import 'bootstrap/dist/css/bootstrap.css';

// SCSS
import '@/assets/scss/app.scss';

import GlobalBaseComponents from '@/plugins/global-base-components';
import GlobalComponents from '@/plugins/global-components';
import GlobalDirectives from '@/plugins/global-directives';

const app = createApp(App);
const pinia = createPinia();
const head = createHead();

createApi({
  baseUrl: BASE_URL,
  baseUrlCDE: BASE_URL_CDE,
  mocks: MOCKS,
});

const { Visa, MasterCard, VisaDankort } = getSchemes();

const visa = new Visa();
const visaDankort = new VisaDankort();
const masterCard = new MasterCard();

visa.addSubScheme(visaDankort);
creditCardDetector.addScheme(visa);
creditCardDetector.addScheme(masterCard);

pinia.use(piniaPersist);
pinia.use(({ store }) => {
  store.$loaderHandler = app.config.globalProperties.$loaderHandler;
});

app.use(pinia);
app.use(GlobalBaseComponents);
app.use(GlobalComponents);
app.use(GlobalDirectives);
app.use(router);
app.use(loaderHandler);
app.use(head);
app.use(modalManager, {
  config: { backdrop: 'static', keyboard: false },
});

app.use(translations, {
  showStaticText: SHOW_STATIC_TEXT,
});

app.use(PrimeVue);
app.component('PrimeDropdown', Dropdown);

app.mount('#app');
