import { ref } from 'vue';
import { Modal } from 'bootstrap';

export function useBootstrapModal() {
  const modalInstance = ref(null);

  function createModal(element, config) {
    modalInstance.value = new Modal(element, config); // Instance of the Bootstrap modal.
    return modalInstance.value;
  }

  function showModal() {
    modalInstance.value?.show();
  }

  function hideModal() {
    modalInstance.value?.hide();
  }

  function disposeModal() {
    if (modalInstance.value) {
      modalInstance.value.dispose();
      modalInstance.value = null;
    }
  }

  return {
    modalInstance,
    createModal,
    showModal,
    hideModal,
    disposeModal,
  };
}
