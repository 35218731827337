<script setup></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    fill="currentColor"
    aria-hidden="true"
  >
    <g stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
      <rect
        fill-opacity="0"
        fill="#FFFFFF"
        x="0"
        y="0"
        width="24"
        height="24"
      />
      <path
        d="M18.75144,6.02622037 L17.9737796,5.24856003 C17.8956996,5.17047999 17.7690795,5.17047999 17.6909995,5.24856003 L11.999997,10.9395625 L6.30901449,5.24856003 C6.23091446,5.17047999 6.1043144,5.17047999 6.02621437,5.24856003 L5.24857403,6.02622037 C5.17047399,6.1043004 5.17047399,6.23092046 5.24857403,6.30900049 L10.9395765,12.000003 L5.24857403,17.6910055 C5.17047399,17.7690855 5.17047399,17.8956856 5.24857403,17.9737856 L6.02621437,18.751446 C6.1043144,18.829526 6.23091446,18.829526 6.30901449,18.751446 L11.999997,13.0604435 L17.6909995,18.751446 C17.7690795,18.829526 17.8956996,18.829526 17.9737796,18.751446 L18.75144,17.9737856 C18.82952,17.8956856 18.82952,17.7690855 18.75144,17.6910055 L13.0604375,12.000003 L18.75144,6.30900049 C18.82952,6.23092046 18.82952,6.1043004 18.75144,6.02622037"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped></style>
