<script setup>
  import { RouterLink } from 'vue-router';

  // Handle keyboard events for the RouterLink component
  const handleKeyDown = (event) => {
    // Handle the space key
    if (event.code === 'Space') {
      event.preventDefault(); // Prevent scrolling when using space button
      event.target.click(); // Trigger the link's click event
    }
  };
</script>

<template>
  <RouterLink class="base-link" @keydown="handleKeyDown">
    <slot />
  </RouterLink>
</template>

<style lang="scss" scoped>
  .base-link {
    color: $link-color;
    cursor: pointer;
    text-decoration: none;
    height: max-content;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: $font-regular-text;

    font-size: 1em;
    line-height: 24px;
    text-align: left;

    &:hover,
    &:focus-visible {
      color: $link-color;
      text-decoration: underline;
    }

    &:focus-visible {
      outline: $primary-color auto 1px;
      outline-offset: 2px;
    }
  }
</style>
