<script setup>
  import { CARDINAL_STEP_UP_URL } from '@/configs/cardinal-config.js';
  import { useCardinalStore } from '@/stores/CardinalStore';
  import { onMounted, onUnmounted, ref } from 'vue';
  import { useCardinalPostMessageHandler } from '@/composables/cardinal-post-message-handler.js';

  const cardinalStore = useCardinalStore();
  const { startStepUpListener, stopStepUpListener } =
    useCardinalPostMessageHandler();

  const cardinalStepUp = ref(null);

  onMounted(async () => {
    startStepUpListener();
    cardinalStepUp.value?.submit();
  });

  onUnmounted(() => {
    stopStepUpListener();
    cardinalStore.$reset();
  });
</script>

<template>
  <div class="iframe-wrapper">
    <iframe
      class="step-up-iframe"
      name="cardinal_step_up_frame"
      :aria-label="
        $translation(
          'cardinal_step_up_iframe_aria_label',
          '3DS Authentication challenge window.',
        )
      "
      height="800"
      width="400"
    >
    </iframe>

    <form
      ref="cardinalStepUp"
      target="cardinal_step_up_frame"
      method="POST"
      :action="CARDINAL_STEP_UP_URL"
    >
      <input type="hidden" name="JWT" :value="cardinalStore.getStepUpToken" />
    </form>
  </div>
</template>

<style lang="scss" scoped>
  .iframe-wrapper {
    display: flex;
    justify-content: center;

    .step-up-iframe {
      height: 600px;
      width: 300px;
      background-color: white;
    }

    @media (min-width: 768px) {
      .step-up-iframe {
        height: 800px;
        width: 400px;
      }
    }
  }
</style>
