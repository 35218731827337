<script setup></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    fill="currentColor"
    aria-hidden="true"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect
        fill-opacity="0"
        fill="#FFFFFF"
        x="0"
        y="0"
        width="24"
        height="24"
      />
      <path
        d="M18.4376719,14.5808616 L21.9199718,11.9706217 C22.0266894,11.8906235 22.0266894,11.7305672 21.9199718,11.650569 L18.4376719,9.04032907 C18.305815,8.94149135 18.1177193,9.03556918 18.1177193,9.20034539 L18.1177193,11.0580627 L2.1999854,11.0580827 C2.08954794,11.0580827 1.99999,11.1476206 1.99999,11.2580781 L1.99999,12.3631326 C1.99999,12.4735701 2.08954794,12.563128 2.1999854,12.563128 L18.1177193,12.563108 L18.1177193,14.4208253 C18.1177193,14.5856215 18.305815,14.6796994 18.4376719,14.5808616"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<style scoped lang="scss">
  // This comment is essential to enable the addition of v-directives to add classes to the class list.
</style>
