<script setup>
  import { computed, inject, ref, resolveComponent } from 'vue';
  import { FOOTER_URLS } from '@/configs/url-config.js';
  import { useUtility } from '@/composables/use-utility.js';
  const { getLocaleBasedUrl } = useUtility();

  const translations = inject('translations');
  const modalManager = inject('modalManager');
  const consentModal = resolveComponent('TheModalConsent');
  const footerUrls = FOOTER_URLS;

  const modalConsentButton = ref(null);

  const footerLinks = computed(() => [
    {
      label: translations.getOne(
        'footer_links_label_1',
        'Our commitment to you',
      ),
      href: getLocaleBasedUrl(footerUrls.label_1_link),
    },
    {
      label: translations.getOne('footer_links_label_2', 'Legal and privacy'),
      href: getLocaleBasedUrl(footerUrls.label_2_link),
    },
    {
      label: translations.getOne('footer_links_label_3', 'Contact us'),
      href: getLocaleBasedUrl(footerUrls.label_3_link),
    },
  ]);

  async function openConsentModal() {
    await modalManager.openUniqueModal(consentModal, {
      focusedElementRef: modalConsentButton.value?.$el,
    });
  }
</script>

<template>
  <footer class="footer">
    <div class="footer-wrapper">
      <div class="top-footer">
        <nav class="footer-links-wrapper">
          <ul class="footer-links">
            <li
              v-for="(link, index) in footerLinks"
              class="footer-link-item"
              :key="`footer-link-${index}`"
            >
              <link-external class="footer-link" :href="link.href">
                {{ link.label }}
              </link-external>
            </li>

            <li class="footer-link-item">
              <button-link
                class="footer-link"
                @click="openConsentModal"
                ref="modalConsentButton"
              >
                {{ $translation('footer_links_label_4', 'Cookies settings') }}
              </button-link>
            </li>
          </ul>
        </nav>

        <div class="language-wrapper">
          <language-select class="language-button"> </language-select>
        </div>
      </div>

      <div class="copyright-wrapper">
        <p class="copyright-text">
          {{
            $translation(
              'footer_copyright',
              'Copyright © Cathay Pacific Airways Limited 國泰航空有限公司',
            )
          }}
        </p>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
  .footer {
    background-color: $primary-color;
    color: $quaternary-color;
    display: flex;
    justify-content: center;

    .footer-wrapper {
      @include content-area-width;

      padding-block: 64px 80px;
      padding-inline: 16px;

      @include md-breakpoint {
        padding-block-end: 96px;
        padding-inline: 32px 31px;
      }

      @include lg-breakpoint {
        padding-block-end: 81px;
        padding-inline: 36px 59px;
      }

      .top-footer {
        @include md-breakpoint {
          display: flex;
          flex-direction: row;
          gap: 30px;
          justify-content: space-between;
        }

        .footer-links-wrapper {
          margin-block-end: 24px;

          @include md-breakpoint {
            margin-block-end: 30px;
          }

          .footer-links {
            display: flex;
            flex-direction: column;
            gap: 24px;
            list-style-type: none;
            padding: 0;
            margin: 0;

            @include md-breakpoint {
              flex-direction: row;
              flex-wrap: wrap;
              gap: 16px;
            }

            .footer-link-item {
              &:not(:last-child) {
                @include md-breakpoint {
                  border-inline-end: solid 1px $border-color;
                  padding-inline-end: 18px;
                }
              }

              .footer-link {
                width: max-content;
                color: $quaternary-color;
                font-size: 1.125em;
                font-family: $font-regular-heading;
                line-height: 28px;
              }
            }
          }
        }

        .language-wrapper {
          border-block: solid 1px $border-color;
          padding-block: 27px 29px;

          @include md-breakpoint {
            padding-block: 0;
            border-block: 0;
            display: flex;
            justify-content: flex-end;
            min-width: 340px;
          }

          .language-button {
            color: $quaternary-color;
            font-size: 1em;
            line-height: 24px;
            width: max-content;

            @include lg-breakpoint {
              font-size: 0.875em;
              line-height: 20px;
            }
          }
        }
      }

      .copyright-wrapper {
        margin-block-start: 40px;

        @include md-breakpoint {
          margin-block-start: 0;
        }

        @include lg-breakpoint {
          margin-block-start: 5px;
        }

        .copyright-text {
          font-size: 0.875em;
          line-height: 20px;
          font-family: $font-regular-heading;
          color: $quaternary-color;
          margin: 0;
        }
      }
    }
  }
</style>
