import axios from 'axios';

// Class representing an HTTP layer using Axios.
class HttpLayerAxios {
  /**
   * Creates an instance of HttpLayerAxios.
   * @param {Object} config - Configuration options for Axios.
   */
  constructor(config = {}) {
    this.httpClient = axios.create(config);
    this.config = config;
    this.baseUrl = config.baseUrl;
  }

  /**
   * A setter method to update the Axios configuration dynamically.
   * This method allows you to change the Axios instance configuration at runtime.
   * It uses a private method `_mergeAxiosConfig` to merge the new configuration
   * with the existing configuration of the Axios instance.
   *
   * @param {Object} config - The configuration object to update Axios with.
   *                          This can include various Axios settings like
   *                          `baseURL`, `headers`, `timeout`, `validateStatus`, etc.
   */
  set setConfig(config) {
    this._mergeAxiosConfig(this.httpClient, config);
  }

  /**
   * Send a generic HTTP request using the configured Axios instance.
   * @param {Object} config - Configuration options for the request.
   * @returns {Promise} - A Promise that resolves to the response data.
   */
  request(config = {}) {
    return this.httpClient.request(config);
  }

  /**
   * Send a GET request to retrieve data from the API.
   * @param {Object} config - Configuration options for the request.
   * @returns {Promise} - A Promise that resolves to the response data.
   */
  get(config = {}) {
    return this.httpClient.get(this.baseUrl, config);
  }

  /**
   * Send a DELETE request to remove a resource from the API.
   * @param {string} identifier - Identifier of the resource to delete.
   * @param {Object} config - Configuration options for the request.
   * @returns {Promise} - A Promise that resolves to the response data.
   */
  delete(identifier = '', config = {}) {
    return this.httpClient.delete(this.baseUrl + `/${identifier}`, config);
  }

  /**
   * Send a HEAD request to retrieve the headers of a resource.
   * @param {Object} config - Configuration options for the request.
   * @returns {Promise} - A Promise that resolves to the response data.
   */
  head(config = {}) {
    return this.httpClient.head(this.baseUrl, config);
  }

  /**
   * Send an OPTIONS request to retrieve the allowed methods for a resource.
   * @param {Object} config - Configuration options for the request.
   * @returns {Promise} - A Promise that resolves to the response data.
   */
  options(config = {}) {
    return this.httpClient.options(this.baseUrl, config);
  }

  /**
   * Send a POST request to create a resource on the API.
   * @param {Object} data - Data to be sent in the request body.
   * @param {Object} config - Configuration options for the request.
   * @returns {Promise} - A Promise that resolves to the response data.
   */
  post(data = {}, config = {}) {
    return this.httpClient.post(this.baseUrl, data, config);
  }

  /**
   * Send a PUT request to update a resource on the API.
   * @param {Object} data - Data to be sent in the request body.
   * @param {Object} config - Configuration options for the request.
   * @returns {Promise} - A Promise that resolves to the response data.
   */
  put(data = {}, config = {}) {
    return this.httpClient.put(this.baseUrl, data, config);
  }

  /**
   * Send a PATCH request to partially update a resource on the API.
   * @param {Object} data - Data to be sent in the request body.
   * @param {Object} config - Configuration options for the request.
   * @returns {Promise} - A Promise that resolves to the response data.
   */
  patch(data = {}, config = {}) {
    return this.httpClient.patch(this.baseUrl, data, config);
  }

  get interceptors() {
    return this.httpClient?.interceptors;
  }

  /**
   * Merges custom configuration settings into an existing Axios instance.
   * This includes general settings like timeout and validateStatus, as well as
   * method-specific headers (e.g., common, get, put).
   *
   * @param {Object} axiosInstance - The Axios instance to configure.
   * @param {Object} config - The custom configuration object. Should contain
   * properties like validateStatus, timeout, and headers.
   * @private
   */
  _mergeAxiosConfig(axiosInstance, config) {
    // Merge non-header configurations like validateStatus, timeout, etc.
    // This ensures that these settings are updated on the axios instance.
    for (const key in config) {
      if (
        Object.prototype.hasOwnProperty.call(config, key) &&
        key !== 'headers'
      ) {
        axiosInstance.defaults[key] = config[key];
      }
    }

    // Iterate over each key in the headers object.
    for (const key in config.headers) {
      if (Object.prototype.hasOwnProperty.call(config.headers, key)) {
        // Merge each set of headers (common, get, put, etc.).
        if (axiosInstance.defaults.headers[key]) {
          Object.assign(
            axiosInstance.defaults.headers[key],
            config.headers[key],
          );
        } else {
          axiosInstance.defaults.headers[key] = config.headers[key];
        }
      }
    }
  }
}

export default HttpLayerAxios;
