// CustomError class is the base class for creating custom exceptions. It extends the built-in Error class.
class CustomError extends Error {
  constructor(name, message = '') {
    super(message);
    this.name = name;
  }
}

// SilentExceptionError is a specific type of custom error representing a silent exception.
class SilentExceptionError extends CustomError {
  constructor(message = '') {
    super('Silent Exception', message);
  }
}

// UnauthorizedExceptionError is a specific type of custom error representing an unauthorized exception.
class UnauthorizedExceptionError extends CustomError {
  constructor(message = '') {
    super('Unauthorized', message);
  }
}

// MessageExceptionError is a specific type of custom error representing a message-related exception.
class MessageExceptionError extends CustomError {
  constructor(message = 'A known exception occurred') {
    super('Message Exception Error', message);
  }
}

// ValidationExceptionErrors is a specific type of custom error representing a validation-related exception.
class ValidationExceptionErrors extends CustomError {
  constructor(messages = ['A known exception occurred']) {
    super('Validation Exception Error', messages.join(', '));
    this.messages = messages;
  }
}

// UnknownExceptionError is a specific type of custom error representing an unknown exception.
class UnknownExceptionError extends CustomError {
  constructor(message = 'An unknown exception occurred') {
    super('Unknown Exception Error', message);
  }
}

// TokenAuthorizationFailedExceptionError is a specific type of custom error representing a token authorization failure.
class TokenAuthorizationFailedExceptionError extends CustomError {
  constructor(message = '') {
    super('Token authorization failed', message);
  }
}

// exceptionsHandler is a function that handles different types of responses (res).
const exceptionsHandler = (res) => {
  // Unauthorized: If response status code is 401, throw an UnauthorizedExceptionError.
  if (res.statusCode === 401) {
    throw new UnauthorizedExceptionError();
  }

  // Known errors (status code 400) handling.
  if (!res.success && res.statusCode === 400) {
    // If the status is 'MESSAGE_ERROR', throw a MessageExceptionError with the provided error message.
    if (res.status === 'MESSAGE_ERROR') {
      throw new MessageExceptionError(res.error);
    }

    // If the status is 'VALIDATION_ERROR', throw a ValidationExceptionErrors with the provided errors.
    if (res.status === 'VALIDATION_ERROR') {
      throw new ValidationExceptionErrors(res.errors);
    }
  }

  // Unknown errors: If response status code is not 200, throw an UnknownExceptionError.
  if (res.statusCode !== 200) {
    throw new UnknownExceptionError();
  }
};

export {
  exceptionsHandler,
  SilentExceptionError,
  UnauthorizedExceptionError,
  MessageExceptionError,
  ValidationExceptionErrors,
  UnknownExceptionError,
  TokenAuthorizationFailedExceptionError,
};
