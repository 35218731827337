<script setup>
  import { computed } from 'vue';

  const emit = defineEmits(['update:modelValue', 'onSelect']);
  const props = defineProps({
    modelValue: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ariaDisabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    inputStateError: {
      type: Boolean,
      default: false,
    },
    inputStateSuccess: {
      type: Boolean,
      default: false,
    },
    stateMsg: {
      type: String,
      default: '',
    },
  });

  const modelValueInput = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });

  const isSelectFilled = computed(() =>
    props.modelValue ? 'select-filled' : '',
  );

  const isAriaDisabled = computed(() => props.ariaDisabled || props.disabled);

  const isAriaLabel = computed(() =>
    props.ariaLabel ? props.ariaLabel : props.label,
  );

  const isAriaDisabledSelect = computed(() =>
    isAriaDisabled.value ? 'aria-disabled-select' : null,
  );

  /**
   * Input has a state.
   * @type {ComputedRef<boolean>}
   */
  const hasState = computed(() => {
    return props.inputStateError || props.inputStateSuccess;
  });

  /**
   * Input State type.
   * @type {ComputedRef<null|string>}
   */
  const stateType = computed(() => {
    return !hasState.value
      ? null
      : props.inputStateSuccess
        ? 'valid'
        : 'invalid';
  });

  /**
   * Input feedback, input class, message class).
   * @type {ComputedRef<{stateMessage: string, stateInput: string}|{stateMessage: null, stateInput: null}>}
   */
  const feedback = computed(() => {
    return hasState.value
      ? {
          stateInput: `is-${stateType.value}`,
          stateMessage: `${stateType.value}-feedback`,
        }
      : { stateInput: null, stateMessage: null };
  });

  function attachRequired(el) {
    const element = el?.$el;
    if (!element) {
      return null;
    }

    const selectInput = element.querySelector('.p-dropdown-label');
    if (!selectInput) {
      return null;
    }

    selectInput.setAttribute('aria-required', props.required);

    return element;
  }

  function onSelect(selected) {
    emit('onSelect', selected);
  }
</script>

<template>
  <div
    class="base-floating-select"
    :class="[isSelectFilled, isAriaDisabledSelect, feedback.stateInput]"
  >
    <prime-dropdown
      :id="id"
      :disabled="isAriaDisabled"
      v-model="modelValueInput"
      :options="options"
      option-label="label"
      @change="onSelect"
      :ref="attachRequired"
      :aria-label="isAriaLabel"
      panel-class="base-floating-select-panel"
    >
      <template #dropdownicon>
        <icon-down aria-hidden="true" />
      </template>

      <template #value="slotProps">
        <slot v-if="slotProps.value" :selected="slotProps.value">
          {{ slotProps.value.label }}
        </slot>
      </template>
    </prime-dropdown>
    <span class="base-label" aria-hidden="true">{{ label }}</span>

    <text-assistive
      :value="stateMsg"
      v-show="stateMsg && hasState"
      :hidden="false"
    >
      <template v-slot="{ textToRead }">
        <p
          :class="[feedback.stateMessage]"
          class="base-input-feedback"
          v-for="(text, index) in textToRead"
          :key="index"
        >
          <icon-error v-if="inputStateError" />
          {{ text }}
        </p>
      </template>
    </text-assistive>
  </div>
</template>

<style lang="scss" scoped>
  @mixin dropdown() {
    :deep(.p-dropdown) {
      display: flex;
      align-items: center;
      height: 60px;
      justify-content: space-between;

      border-radius: 0;
      border: 1px solid $border-color;
      padding-block: 0;
      padding-inline: 24px;

      font-family: $font-regular-text;
      font-size: 1em;
      line-height: 24px;
      cursor: pointer;

      &:focus-within {
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
      }

      .p-dropdown-label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $duodecenary-color;
        max-width: 704px;
        width: 100%;
        height: 100%;

        &:focus-visible {
          outline: none;
        }
      }

      &.p-overlay-open {
        .p-dropdown-trigger {
          transition: transform 0.2s ease;
          transform: scaleY(-1);
        }
      }
    }
  }

  @mixin filled-dropdown() {
    :deep(.p-dropdown) {
      .p-dropdown-label {
        padding-block-start: 1.625rem;
        padding-block-end: 0.625rem;
      }
    }
  }

  @mixin disable-dropdown() {
    &.aria-disabled-select {
      &:focus-visible {
        outline: 2px solid $primary-color;
        outline-offset: 2px;
        border-radius: 8px;
      }

      :deep(.p-dropdown) {
        cursor: not-allowed;
        background: $duonary-color;

        &:focus-within {
          box-shadow: none;
        }

        .p-dropdown-label {
          color: $senary-color;
        }
      }
    }
  }

  @mixin invalid-dropdown() {
    &.is-invalid {
      :deep(.p-dropdown) {
        border-color: $tertiary-color;
        &:focus-within {
          box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
        }
      }
    }
  }

  @mixin valid-dropdown() {
    &.is-valid {
      :deep(.p-dropdown) {
        &:focus-within {
          box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
        }
      }
    }
  }

  .base-floating-select {
    max-width: 704px;
    width: 100%;
    margin-block-end: 16px;
    position: relative;

    @include md-breakpoint {
      max-width: 556px;
    }

    @include dropdown;

    .base-label {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 49;
      height: 100%;
      padding: 1rem 0.75rem;
      overflow: hidden;
      text-align: start;
      text-overflow: ellipsis;
      white-space: nowrap;
      pointer-events: none;
      transform-origin: 0 0;
      transition:
        opacity 0.1s ease-in-out,
        transform 0.1s ease-in-out;
      cursor: pointer;

      padding-inline: 24px;
      padding-block: 18px;
      width: 100%;
      font-family: $font-regular-text;
      color: $duodecenary-color;
    }

    &.select-filled {
      @include filled-dropdown;

      .base-label {
        color: $senary-color;
        font-size: 0.75em;
        line-height: 16px;
        transform: scale(0.9) translateY(-0.5rem) translateX(0.21rem);
        transform-origin: 0 0;
        transition: transform 0.1s ease-in-out;
        top: 0;
        left: 0;
      }
    }

    @include disable-dropdown;
    @include invalid-dropdown;
    @include valid-dropdown;

    .base-input-feedback {
      font-size: 0.75em;
      line-height: 16px;
      font-family: $font-regular-text;

      &.invalid-feedback {
        color: $tertiary-color;
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }

  html[dir='rtl'] {
    .select-filled {
      .base-label {
        transform: scale(0.9) translateY(-0.5rem) translateX(-0.21rem);
        top: 0;
        right: 0;
        transform-origin: 100% 0;
        transition:
          opacity 0.1s ease-in-out,
          transform 0.1s ease-in-out;
      }
    }
  }
</style>

<!-- Panel styled in un-scoped section in order to work  -->
<style lang="scss">
  @mixin dropdown-panel() {
    .base-floating-select-panel {
      padding-inline: 16px;
      max-width: 1px;

      @include custom-breakpoint(470px) {
        padding-inline: 0;
      }
      .p-dropdown-items-wrapper {
        .p-dropdown-items {
          background-color: $quaternary-color;
          border-inline: 1px solid $border-color;
          list-style: none;
          margin: 0;
          padding: 0;

          .p-dropdown-item {
            width: 100%;
            text-align: start;
            border-radius: 0;

            background: $quaternary-color;
            padding-block: 15px;
            padding-inline: 24px;
            text-align: start;
            border-radius: 0;
            max-height: max-content;
            font-size: 1em;
            color: $duodecenary-color;
            font-family: $font-regular-text;
            border-bottom: 1px solid $border-color;
            cursor: pointer;

            .p-dropdown-item-label {
              color: $duodecenary-color;
            }

            &.p-focus {
              background: $secondary-color;
              border-bottom: 1px solid $secondary-color;

              .p-dropdown-item-label {
                color: $quaternary-color;
              }
            }

            &.p-highlight {
              font-family: $font-bold-text;
            }
          }
        }
      }
    }
  }

  @include dropdown-panel;
</style>
