<script setup></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    fill="currentColor"
    aria-hidden="true"
  >
    <title>
      {{ $translation('aria_label_icon_another_page', 'opens new tab') }}
    </title>
    <g stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
      <rect
        fill-opacity="0"
        fill="#FFFFFF"
        x="0"
        y="0"
        width="24"
        height="24"
      />
      <path
        d="M20.5015093,14.040585 C20.5015093,14.2888851 20.3002292,14.4901652 20.051949,14.4901652 L7.94406297,14.4901652 C7.69578285,14.4901652 7.49450275,14.2888851 7.49450275,14.040585 L7.49450275,5.94816097 C7.49450275,5.69988085 7.69578285,5.49858075 7.94406297,5.49858075 L20.051949,5.49858075 C20.3002292,5.49858075 20.5015093,5.69988085 20.5015093,5.94816097 L20.5015093,14.040585 Z M20.051829,4 L7.94406297,4 C6.86818243,4 5.996002,4.87216044 5.996002,5.94804097 L5.996002,8.01124201 L3.94806097,8.01124201 C2.87218044,8.01124201 2,8.88340244 2,9.95928298 L2,18.051827 C2,19.1277676 2.87222044,20 3.94818097,20 L5.8062019,20 C5.91654196,20 6.006002,19.910548 6.006002,19.8002079 L6.006002,18.7012274 C6.006002,18.5908673 5.91654196,18.5014073 5.8061819,18.5014073 L3.94806097,18.5014073 C3.69978085,18.5014073 3.49850075,18.3001272 3.49850075,18.051847 L3.49850075,9.95938298 C3.49850075,9.71110286 3.69978085,9.50982275 3.94806097,9.50982275 L5.996002,9.50982275 L5.996002,14.040705 C5.996002,15.1165856 6.86818243,15.988766 7.94406297,15.988766 L16.5055073,15.988766 L16.5055073,18.051847 C16.5055073,18.3001272 16.3042272,18.5014073 16.055947,18.5014073 L8.70450335,18.5014073 C8.04176302,18.5014073 7.50450275,19.0386675 7.50450275,19.7014079 L7.50450275,19.8002079 C7.50450275,19.910548 7.5939428,20 7.70430285,20 L16.055947,20 C17.1318276,20 18.004008,19.1278276 18.004008,18.051947 L18.004008,15.988766 L20.051949,15.988766 C21.1278296,15.988766 22.00001,15.1165856 22.00001,14.040705 L22.00001,5.94816097 C22.00001,4.87222044 21.1277896,4 20.051829,4 L20.051829,4 Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped></style>
