<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    modelValue: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    stateMsg: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  });

  defineEmits(['update:modelValue']);

  const isChecked = computed(() => (props.modelValue ? 'bold' : ''));

  const hasState = computed(() => props.stateMsg && !props.modelValue);

  const isError = computed(() => (hasState.value ? 'is-error' : ''));

  const handleKeyDown = (event) => {
    // Handle the enter key
    if (event.code === 'Enter') {
      event.preventDefault(); // Prevent scrolling when using space button
      event.target.click(); // Trigger the link's click event
    }
  };
</script>

<template>
  <div class="checkbox-wrapper">
    <div class="checkbox-primary">
      <input
        class="form-check-input checkbox-input"
        :class="[isError]"
        type="checkbox"
        @input="$emit('update:modelValue', $event.target.checked)"
        @keydown="handleKeyDown"
        :checked="modelValue"
        :id="id"
        aria-required="true"
        :disabled="disabled"
        :aria-disabled="disabled"
      />
      <label
        :class="[isChecked]"
        class="form-check-label checkbox-label"
        :for="id"
      >
        <slot />
      </label>
    </div>

    <text-assistive
      :value="stateMsg"
      v-show="stateMsg && hasState"
      :hidden="false"
    >
      <template v-slot="{ textToRead }">
        <p
          :class="[feedback.stateMessage]"
          class="checkbox-feedback-error"
          v-for="(text, index) in textToRead"
          :key="index"
        >
          <icon-error v-if="hasState" />
          {{ text }}
        </p>
      </template>
    </text-assistive>
  </div>
</template>

<style lang="scss" scoped>
  .checkbox-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .checkbox-primary {
      margin: 0;
      display: flex;
      align-items: stretch;
      gap: 8px;

      .checkbox-input {
        border-radius: 0;
        width: 24px;
        height: 24px;
        border-color: $border-color;
        margin: 0;
        background-image: url('@/assets/images/checkbox-checkmark.svg');
        background-size: 0;
        cursor: pointer;
        font-size: 1em;

        &:checked {
          background-color: $primary-color;
          border-color: $primary-color;
          background-size: 20px;
        }

        &:disabled {
          background-color: $duonary-color;
          background-image: url('@/assets/images/checkbox-checkmark-disabled.svg');
        }

        &.is-error {
          border-color: $tertiary-color;
        }
      }
      .checkbox-label {
        color: $duodecenary-color;
        font-family: $font-regular-text;
        cursor: pointer;

        &.bold {
          font-family: $font-bold-text;
        }
      }
    }
    .checkbox-feedback-error {
      font-size: 0.75em;
      line-height: 16px;
      font-family: $font-regular-text;
      color: $tertiary-color;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
</style>
