/**
 * Creates a navigation guard for a specific phase of the navigation process.
 * @param {GuardAbstraction} Guard - A GuardAbstraction class representing the navigation guard for the specified phase.
 * @param router
 * @returns {function} - A function that serves as a navigation guard for the specified phase, created from the provided guard class.
 */
const createPhaseNavigationGuard = (Guard, router = null) => {
  /**
   * Navigation guard function for the specified phase.
   * @param {...any} args - Arguments required for the navigation guard and the original navigation function.
   */
  return (...args) => new Guard(router).setupGuard(...args);
};

export default createPhaseNavigationGuard;
