import { defineStore } from 'pinia';
import { useApi, getServices } from 'api';
import { useUserStore } from '@/stores/UserStore';

export const useCardinalStore = defineStore('cardinal', {
  state: () => ({
    stepUpToken: null,
  }),

  actions: {
    async authenticateService(transactionId) {
      const loaderKey = 'userStore::loader::addCard';

      const api = useApi();
      const { LookUpAuthenticateService } = getServices();

      const options = {
        params: {
          transactionId: transactionId,
          cardPublicId: useUserStore().cardPublicId,
          cardNumber: useUserStore().cardNumber,
        },
      };

      useUserStore().cardNumber = null;
      useUserStore().cardPublicId = null;

      await api.call(LookUpAuthenticateService, options);

      this.stopLoader(loaderKey);
    },

    startLoader(key) {
      this.$loaderHandler.start(key);
    },

    stopLoader(key) {
      this.$loaderHandler.stop(key);
    },

    isLoading(key) {
      return this.$loaderHandler.isLoading(key);
    },
  },
  getters: {
    getStepUpToken: (state) => state.stepUpToken,
  },
});
