import { defineStore } from 'pinia';

export const useRouteStore = defineStore('routeStore', {
  state: () => ({
    previousRoute: null,
  }),
  actions: {
    setPreviousRoute(route) {
      this.previousRoute = route;
    },
  },
});
