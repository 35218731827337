<script setup></script>

<template>
  <div class="card base-card">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .base-card {
    padding-block: 30px 24px;
    padding-inline: 24px 24px;
    border-radius: 8px;
    border-color: $border-color;
  }
</style>
