import { reactive } from 'vue';

export default {
  install: (app) => {
    // Check for Vue 3 compatibility.
    const [majorVersion, minorVersion] = app.version.split('.').map(Number);
    if (majorVersion < 3) {
      console.warn('This plugin requires Vue 3');
      return;
    }

    // Creating a reactive map to keep track of loader states.
    const loaders = reactive(new Map());

    // Defining the loaderHandler object with the following functionalities:
    // - start: Starts a loader with a given key, setting its state to true.
    // - stop: Stops a loader with a given key, setting its state to false.
    // - stopAll: Stops all loaders by setting their states to false.
    // - isLoading: Checks if a loader with a given key is currently active (loading).
    // - loaders: Exposes the loaders map, allowing direct access if needed.
    const loaderHandler = {
      start: (key) => loaders.set(key, true),
      stop: (key) => loaders.set(key, false),
      stopAll: () => {
        loaders.forEach((_, key) => loaders.set(key, false));
      },
      isLoading: (key) => loaders.get(key) === true,
      loaders: () => loaders,
    };

    // Provide the loader functions to the Vue application.
    const provideTranslations = () => {
      app.provide('loaderHandler', loaderHandler);
    };

    // Special handling for Vue 3 versions below 3.3.
    if (majorVersion === 3 && minorVersion < 3) {
      // Enables utilization in global guards within vue-router version 4.2.0. or above.
      app.runWithContext(provideTranslations);
    } else {
      provideTranslations();
    }

    // Attaching loaderHandler to global properties for access in components.
    app.config.globalProperties.$loaderHandler = loaderHandler;
  },
};
