<script setup>
  import { computed, inject } from 'vue';

  const modalManager = inject('modalManager');

  const props = defineProps({
    title: String,
    text: String,
    id: {
      type: String,
      default: 'modal-id',
    },
    modalSize: String,
    maxWidth: String,
    fullscreenModal: {
      type: Boolean,
      default: false,
    },
    fade: {
      type: Boolean,
      default: true,
    },
  });

  const isFullscreen = computed(() =>
    props.fullscreenModal ? 'modal-fullscreen' : 'base-modal-default-width',
  );

  async function closeModal() {
    await modalManager.closeModal();
  }
</script>

<template>
  <div
    class="modal base-modal"
    :class="{ fade: fade }"
    :aria-describedby="`${id}-desc`"
    tabindex="1"
    ref="baseModal"
  >
    <div
      class="modal-dialog modal-dialog-centered base-modal-dialog"
      :class="[isFullscreen]"
      :id="`${id}-desc`"
    >
      <slot name="custom-content" :close="closeModal">
        <div class="modal-content base-modal-content">
          <div class="modal-header base-modal-header">
            <slot name="modal-header">
              <h1
                :id="`${id}-title`"
                class="modal-title base-modal-title"
                v-if="title"
              >
                {{ title }}
              </h1>
            </slot>
          </div>

          <div class="modal-body base-modal-body">
            <p class="base-modal-text" v-if="text">{{ text }}</p>
            <slot :close="closeModal" :modal-id="id" />
          </div>

          <div class="base-modal-footer">
            <slot name="footer" :close="closeModal" />
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .base-modal {
    .base-modal-dialog {
      &.base-modal-default-width {
        @include md-breakpoint {
          max-width: 524px;
        }

        @include lg-breakpoint {
          max-width: 672px;
        }
      }

      .base-modal-content {
        border-radius: 0;
        padding-inline: 16px;

        @include md-breakpoint {
          padding-inline: 24px;
        }

        .base-modal-header {
          padding-block-start: 24px;
          padding-block-end: 0;
          padding-inline: 0;
          border-bottom: 0;

          @include lg-breakpoint {
            padding-block-start: 40px;
          }
        }

        .base-modal-body {
          padding-block-start: 8px;
          padding-block-end: 0;
          padding-inline: 0;

          .base-modal-text {
            margin: 0;
          }
        }

        .base-modal-footer {
          border-top: 0;
          padding-inline: 0;
          padding-block: 24px;
          display: flex;
          flex-direction: column;
          gap: 16px;

          @include md-breakpoint {
            flex-direction: row-reverse;
            justify-content: flex-start;
          }

          @include lg-breakpoint {
            padding-block-end: 40px;
          }
        }
      }
    }
  }
</style>
