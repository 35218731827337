<script setup></script>

<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect
        fill-opacity="0"
        fill="#FFFFFF"
        x="0"
        y="0"
        width="24"
        height="24"
      ></rect>
      <path
        d="M12,16.291325 L11.9999319,16.291325 C11.6354653,16.291325 11.297628,16.1100861 11.0961828,15.8065582 L4.03814828,7.60769411 C3.9683179,7.50245709 3.99705007,7.36053341 4.10230994,7.29074874 L4.1962781,7.22846142 C4.82733446,6.81018771 5.67796224,6.98237503 6.09664739,7.61315711 L12,14.0724561 L17.9033536,7.61315711 C18.3220388,6.98237503 19.1726665,6.81018771 19.8037229,7.22846142 L19.8976911,7.29074874 C20.0029509,7.36053341 20.0316831,7.50245709 19.9618527,7.60769411 L12.9037954,15.8066268 C12.7023044,16.1101318 12.3644442,16.291325 12,16.291325"
        fill="#2D2D2D"
      ></path>
    </g>
  </svg>
</template>

<style scoped lang="scss">
  // This comment is essential to enable the addition of v-directives to add classes to the class list.
</style>
