// Templates
import AppTemplateDefault from '@/components/layout/templates/AppTemplateDefault.vue';

// Layout
import TheHeader from '@/components/layout/TheHeader.vue';
import TheFooter from '@/components/layout/TheFooter.vue';
import TheMobileNavigation from '@/components/layout/TheMobileNavigation.vue';
import TheProfileDropdown from '@/components/layout/TheProfileDropdown.vue';

// Buttons
import ButtonPrimary from '@/components/ui/buttons/ButtonPrimary.vue';
import ButtonSecondary from '@/components/ui/buttons/ButtonSecondary.vue';
import ButtonRemove from '@/components/ui/buttons/ButtonRemove.vue';
import ButtonLink from '@/components/ui/buttons/ButtonLink.vue';
import ButtonClose from '@/components/ui/buttons/ButtonClose.vue';
import ButtonSignOut from '@/components/ui/buttons/ButtonSignOut.vue';

// Language select
import LanguageSelect from '@/components/layout/language-select/LanguageSelect.vue';

// Cards
import CardLinked from '@/components/ui/cards/CardLinked.vue';

// Icons
import IconTrashcan from '@/components/icons/IconTrashcan.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import IconBell from '@/components/icons/IconBell.vue';
import IconCheckmarkFill from '@/components/icons/IconCheckmarkFill.vue';
import IconError from '@/components/icons/IconError.vue';
import IconProfile from '@/components/icons/IconProfile.vue';
import IconQuestion from '@/components/icons/IconQuestion.vue';
import IconDown from '@/components/icons/IconDown.vue';
import IconClose from '@/components/icons/IconClose.vue';
import IconGlobe from '@/components/icons/IconGlobe.vue';
import IconAnotherPage from '@/components/icons/IconAnotherPage.vue';
import IconPaymentCard from '@/components/icons/IconPaymentCard.vue';
import IconSpinner from '@/components/icons/IconSpinner.vue';

// Icon Tiers
import IconMemberAM from '@/components/icons/tiers/IconMemberAM.vue';
import IconMemberSL from '@/components/icons/tiers/IconMemberSL.vue';
import IconMemberGO from '@/components/icons/tiers/IconMemberGO.vue';
import IconMemberDM from '@/components/icons/tiers/IconMemberDM.vue';

// Tiers
import ContentMemberInfo from '@/components/content/ContentMemberInfo.vue';

// Logos
import LogoMcCls from '@/components/logos/schemes/LogoMcCls.vue';
import LogoVisaVop from '@/components/logos/schemes/LogoVisaVop.vue';
import LogoCathay from '@/components/logos/LogoCathay.vue';
import LogoOneWorld from '@/components/logos/LogoOneWorld.vue';
import LogoCathayShort from '@/components/logos/LogoCathayShort.vue';

// Alerts
import AlertPrimarySuccess from '@/components/ui/alerts/AlertPrimarySuccess.vue';
import AlertPrimaryError from '@/components/ui/alerts/AlertPrimaryError.vue';
import AlertPrimarySet from '@/components/ui/alerts/AlertPrimarySet.vue';
import AlertInfo from '@/components/ui/alerts/AlertInfo.vue';
import AlertNotification from '@/components/ui/alerts/AlertNotification.vue';

// Modals
import TheModalGeneralError from '@/components/ui/modals/TheModalGeneralError.vue';
import TheModalUnauthorizedError from '@/components/ui/modals/TheModalUnauthorizedError.vue';
import TheModalRemoveCardAction from '@/components/ui/modals/TheModalRemoveCardAction.vue';
import TheModalLoader from '@/components/ui/modals/TheModalLoader.vue';
import TheCardinalModal from '@/components/ui/modals/TheCardinal.vue';

// Consent
import TheModalConsent from '@/components/ui/modals/consent-modal/TheModalConsent.vue';
import ConsentDescription from '@/components/ui/modals/consent-modal/ConsentDescription.vue';
import ConsentCookieDetail from '@/components/ui/modals/consent-modal/ConsentCookieDetail.vue';

// Forms
import CheckboxPrimary from '@/components/ui/forms/CheckboxPrimary.vue';
import FloatingInputCardnumber from '@/components/ui/forms/FloatingInputCardnumber.vue';
import FloatingInputCardDate from '@/components/ui/forms/FloatingInputCardDate.vue';
import FloatingSelectCardIssuerCountry from '@/components/ui/forms/FloatingSelectCardIssuerCountry.vue';

// Captcha
import InvisibleCaptcha from '@/components/ui/InvisibleCaptcha.vue';

// Links
import LinkAccountOverview from '@/components/ui/links/LinkAccountOverview.vue';
import LinkHelpAndSupport from '@/components/ui/links/LinkHelpAndSupport.vue';
import LinkSkipMainContent from '@/components/ui/links/LinkSkipMainContent.vue';

// Cardinal
import CardinalStepUp from '@/components/cardinal/iframes/CardinalStepUp.vue';

// Accessible components
import TextAssistive from '@/components/ui/TextAssistive.vue';

export default {
  install: (app) => {
    // Cardinal
    app.component('CardinalStepUp', CardinalStepUp);

    // Templates
    app.component('AppTemplateDefault', AppTemplateDefault);

    // Layout
    app.component('TheHeader', TheHeader);
    app.component('TheFooter', TheFooter);
    app.component('TheMobileNavigation', TheMobileNavigation);
    app.component('TheProfileDropdown', TheProfileDropdown);

    // Buttons
    app.component('ButtonPrimary', ButtonPrimary);
    app.component('ButtonSecondary', ButtonSecondary);
    app.component('ButtonRemove', ButtonRemove);
    app.component('ButtonLink', ButtonLink);
    app.component('ButtonClose', ButtonClose);
    app.component('ButtonSignOut', ButtonSignOut);

    // Language Select
    app.component('LanguageSelect', LanguageSelect);

    // Cards
    app.component('CardLinked', CardLinked);

    // Icons
    app.component('IconTrashcan', IconTrashcan);
    app.component('IconArrow', IconArrow);
    app.component('IconBell', IconBell);
    app.component('IconCheckmarkFill', IconCheckmarkFill);
    app.component('IconError', IconError);
    app.component('IconProfile', IconProfile);
    app.component('IconQuestion', IconQuestion);
    app.component('IconDown', IconDown);
    app.component('IconClose', IconClose);
    app.component('IconGlobe', IconGlobe);
    app.component('IconAnotherPage', IconAnotherPage);
    app.component('IconPaymentCard', IconPaymentCard);
    app.component('IconSpinner', IconSpinner);

    // Icon Tiers
    app.component('IconMemberAM', IconMemberAM);
    app.component('IconMemberGO', IconMemberGO);
    app.component('IconMemberSL', IconMemberSL);
    app.component('IconMemberDM', IconMemberDM);

    // Tiers
    app.component('ContentMemberInfo', ContentMemberInfo);

    // Logos
    app.component('LogoMcCls', LogoMcCls);
    app.component('LogoVisaVop', LogoVisaVop);
    app.component('LogoCathay', LogoCathay);
    app.component('LogoCathayShort', LogoCathayShort);
    app.component('LogoOneWorld', LogoOneWorld);

    // Alerts
    app.component('AlertPrimarySuccess', AlertPrimarySuccess);
    app.component('AlertPrimaryError', AlertPrimaryError);
    app.component('AlertPrimarySet', AlertPrimarySet);
    app.component('AlertInfo', AlertInfo);
    app.component('AlertNotification', AlertNotification);

    // Modals
    app.component('TheModalRemoveCardAction', TheModalRemoveCardAction);
    app.component('TheModalLoader', TheModalLoader);
    app.component('TheCardinalModal', TheCardinalModal);

    // Consent
    app.component('TheModalConsent', TheModalConsent);
    app.component('ConsentDescription', ConsentDescription);
    app.component('ConsentCookieDetail', ConsentCookieDetail);

    // Modals Errors
    app.component('TheModalGeneralError', TheModalGeneralError);
    app.component('TheModalUnauthorizedError', TheModalUnauthorizedError);

    // forms
    app.component('CheckboxPrimary', CheckboxPrimary);
    app.component('FloatingInputCardnumber', FloatingInputCardnumber);
    app.component('FloatingInputCardDate', FloatingInputCardDate);
    app.component(
      'FloatingSelectCardIssuerCountry',
      FloatingSelectCardIssuerCountry,
    );

    // Captcha
    app.component('InvisibleCaptcha', InvisibleCaptcha);

    // Links
    app.component('LinkAccountOverview', LinkAccountOverview);
    app.component('LinkHelpAndSupport', LinkHelpAndSupport);
    app.component('LinkSkipMainContent', LinkSkipMainContent);

    // Accessible components
    app.component('TextAssistive', TextAssistive);
  },
};
