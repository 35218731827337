// Importing the Vue Router library.
import * as VueRouter from 'vue-router';

// Destructuring and renaming createRouter function for convenience.
const { createRouter: vueCreateRouter } = VueRouter;

// Exporting Vue Router methods and vueCreateRouter.
export { vueCreateRouter };
export default VueRouter;

/**
 * Enum containing different phases of navigation.
 * These phases correspond to specific points in the navigation process.
 * @enum {string}
 */
export const NAVIGATION_PHASES = {
  BEFORE_ENTER: 'beforeEnter', // Per route navigation guard.
  BEFORE_EACH: 'beforeEach', // Global navigation guard.
  BEFORE_RESOLVE: 'beforeResolve', // Global navigation guard.
  AFTER_EACH: 'afterEach', // Global navigation guard.
};
