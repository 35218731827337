export const HEADER_URLS = {
  help_and_support_link:
    'https://www.cathaypacific.com/cx/[locale]/contact-us.html',
  account_overview_link:
    'https://www.cathaypacific.com/cx/[locale]/membership/my-account.html',
  one_world_link:
    'https://www.cathaypacific.com/cx/[locale]/rewards-and-partnerships/oneworld.html',
};

export const FOOTER_URLS = {
  label_1_link:
    'https://www.cathaypacific.com/cx/[locale]/our-commitment-to-you.html',
  label_2_link:
    'https://www.cathaypacific.com/cx/[locale]/legal-and-privacy/customer-privacy-policy.html',
  label_3_link: 'https://www.cathaypacific.com/cx/[locale]/contact-us.html',
};

export const MANAGE_CARDS_URLS = {
  no_valid_email_link:
    'https://www.cathaypacific.com/cx/[locale]/membership/my-account/profile.html?tab=personal-information',
};

export const LINK_CARD_URLS = {
  terms_and_conditions_link:
    'https://www.cathaypacific.com/cx/[locale]/membership/payment/terms-and-conditions.html',
  privacy_policy_link:
    'https://www.cathaypacific.com/cx/[locale]/legal-and-privacy/customer-privacy-policy.html',
};
