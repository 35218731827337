<script setup>
  import { computed, inject } from 'vue';

  // Inject
  const translations = inject('translations');

  const props = defineProps({
    title: String,
    message: String,
    button: String,
  });

  const titleText = computed(() => {
    return props.title
      ? props.title
      : translations.getOne(
          'the_modal_general_error_title',
          "It appears that something didn't go as expected.",
        );
  });

  const messageText = computed(() => {
    return props.message
      ? props.message
      : translations.getOne(
          'the_modal_general_error_message',
          'Please attempt once more, and if the problem continues, consider closing the browser tab and reopening it.',
        );
  });

  const buttonLabel = computed(() => {
    return props.button
      ? props.button
      : translations.getOne('the_modal_general_error_button_label', 'Ok');
  });
</script>

<template>
  <base-modal id="generic-error" :title="titleText" :text="messageText">
    <template v-slot:footer="{ close }">
      <button-primary @click="close"> {{ buttonLabel }} </button-primary>
    </template>
  </base-modal>
</template>

<style lang="scss" scoped></style>
