import vueRouter from './src/vue-router.js';
import createRouterExtended from './src/create-router.js';
import GuardAbstractionClass from './src/guard-abstraction.js';

// Vue Router original named exports (e.g., RouterLink, RouterView, etc.).
export const NavigationFailureType = vueRouter.NavigationFailureType;
export const RouterLink = vueRouter.RouterLink;
export const RouterView = vueRouter.RouterView;
export const START_LOCATION = vueRouter.START_LOCATION;
export const createMemoryHistory = vueRouter.createMemoryHistory;
export const createRouterMatcher = vueRouter.createRouterMatcher;
export const createWebHashHistory = vueRouter.createWebHashHistory;
export const createWebHistory = vueRouter.createWebHistory;
export const isNavigationFailure = vueRouter.isNavigationFailure;
export const loadRouteLocation = vueRouter.loadRouteLocation;
export const matchedRouteKey = vueRouter.matchedRouteKey;
export const onBeforeRouteLeave = vueRouter.onBeforeRouteLeave;
export const onBeforeRouteUpdate = vueRouter.onBeforeRouteUpdate;
export const parseQuery = vueRouter.parseQuery;
export const routeLocationKey = vueRouter.routeLocationKey;
export const routerKey = vueRouter.routerKey;
export const routerViewLocationKey = vueRouter.routerViewLocationKey;
export const stringifyQuery = vueRouter.stringifyQuery;
export const useLink = vueRouter.useLink;
export const useRoute = vueRouter.useRoute;
export const useRouter = vueRouter.useRouter;
export const viewDepthKey = vueRouter.viewDepthKey;

// Custom GuardAbstraction class for creating navigation guards.
export const GuardAbstraction = GuardAbstractionClass;

/**
 * Overrides the createRouter function with additional functionality for attaching navigation guards.
 * @param {Object} options - Options for creating the Vue Router instance.
 * @returns {vueRouter} - The configured Vue Router instance.
 */
export const createRouter = createRouterExtended;
