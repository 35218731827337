<script setup></script>

<template>
  <button class="button-link" ref="buttonLink">
    <slot />
  </button>
</template>

<style lang="scss" scoped>
  .button-link {
    color: $link-color;
    padding: 0;
    border: 0;
    background: none;
    height: max-content;
    width: max-content;
    display: flex;
    font-size: 1em;

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }

    &:focus-visible {
      outline: $primary-color auto 1px;
      outline-offset: 2px;
    }

    &:disabled {
      border: unset;
      color: $button-disabled-color;
      background: unset;
      cursor: not-allowed;
      opacity: 1;

      &:hover {
        text-decoration: none;
        color: $button-disabled-color;
        background: unset;
      }
    }
  }
</style>
