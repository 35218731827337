<script setup>
  import { computed, inject } from 'vue';
  import { HEADER_URLS } from '@/configs/url-config.js';
  import { useUtility } from '@/composables/use-utility.js';
  const { getLocaleBasedUrl } = useUtility();

  const translations = inject('translations');
  const headerUrls = HEADER_URLS;

  const label = computed(() =>
    translations.getOne('link_help_and_support_link_label', 'Help and support'),
  );

  const localeUrl = computed(() =>
    getLocaleBasedUrl(headerUrls.help_and_support_link),
  );
</script>

<template>
  <link-external :href="localeUrl">
    <slot :label="label">
      {{ label }}
    </slot>
  </link-external>
</template>

<style lang="scss" scoped></style>
