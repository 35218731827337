import { GuardAbstraction } from 'vue-guarded-router';
import { useAuthStore } from '@/stores/AuthStore';

export default class VerifyTokenGuard extends GuardAbstraction {
  isApplied(to) {
    return (
      !useAuthStore().isLoggedIn &&
      !useAuthStore().isAuthenticationInProgress &&
      (to.name === 'VerifyToken' || to.params.locale === 'verify-token')
    );
  }

  async handle(to, from, next) {
    const newQueryParams = Object.assign({}, to.query);
    const authenticationQueryParams = Object.assign({}, to.query);

    try {
      useAuthStore().startAuthenticationProcess();

      // Remove query params from url.
      delete newQueryParams.code;
      delete newQueryParams.nonce;
      delete newQueryParams.state;

      this.router.replace({
        params: to.params,
        query: newQueryParams,
      });

      await useAuthStore().verifyToken(authenticationQueryParams);
      useAuthStore().stopAuthenticationProcess();

      next({ name: 'ManageCards', params: to.params, query: newQueryParams });
    } catch (e) {
      next({ name: 'AccessDenied', query: newQueryParams });
    }
  }
}
