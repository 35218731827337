<script setup></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="24px"
    viewBox="0 0 32 32"
    version="1.1"
    fill="currentColor"
    aria-hidden="true"
  >
    <title>
      {{ $translation('aria_label_icon_bell', 'notification') }}
    </title>
    <g stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
      <rect
        fill-opacity="0"
        fill="#FFFFFF"
        x="0"
        y="0"
        width="32"
        height="32"
      />
      <path
        d="M23.9032151,20.3882462 C21.2414166,16.7287064 23.0475263,8.20515812 16.6537667,7.78960569 C16.8860293,7.60492725 17.0392065,7.32389214 17.0392065,7.00569295 C17.0392065,6.45163688 16.5827387,6 16.0228029,6 C15.4598245,6 15.0053711,6.45165764 15.0053711,7.00569295 C15.0053711,7.33190629 15.1635842,7.6189624 15.4060656,7.80264425 C9.34681002,8.20515812 10.6226335,16.7648116 7.98823894,20.3882254 C7.5429132,20.9995017 6.11362385,22.5161072 6,24.2535171 L14.332956,24.2535171 C14.332956,25.2351884 15.0126217,26 16.0148407,26 C17.0150453,26 17.8205051,25.2351884 17.8630799,24.2535171 L26,24.2535171 C25.8894187,22.516128 24.3465055,20.9995225 23.9032151,20.3882462 Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped></style>
