<script setup>
  import { inject, resolveComponent } from 'vue';
  import { RouterView, useRoute, useRouter } from 'vue-router';
  import { useAuthStore } from '@/stores/AuthStore';
  import { useLocaleStore } from '@/stores/LocaleStore';
  import { storeToRefs } from 'pinia';
  import { getInterceptors } from 'api';
  import { useHead } from '@vueuse/head';
  import { useConsentStore } from '@/stores/ConsentStore';

  const consentStore = useConsentStore();
  const authStore = useAuthStore();
  const localeStore = useLocaleStore();

  const { getLocale } = storeToRefs(localeStore);

  const loaderHandler = inject('loaderHandler');
  const modalManager = inject('modalManager');
  const translations = inject('translations');

  const consentModal = resolveComponent('TheModalConsent');
  const modalLoader = resolveComponent('TheModalLoader');
  const unauthorizedErrorModal = resolveComponent('TheModalUnauthorizedError');
  const generalErrorModal = resolveComponent('TheModalGeneralError');

  const router = useRouter();
  const route = useRoute();

  modalManager.registerModal('modal-loader', modalLoader);
  modalManager.registerModal('modal-general-error', generalErrorModal);

  getInterceptors().response.use(
    function (response) {
      // Any status code that lies within the range of 200 - 300 will trigger this function.
      return response;
    },
    async function (error) {
      loaderHandler.stopAll();

      if (error.config && error.config.skipInterceptor) {
        return Promise.reject(error);
      }

      if (error.response && error.response.status === 401) {
        authStore.killAuth();

        await router.push({
          name: 'AccessDenied',
          params: { locale: getLocale.value },
        });

        await modalManager.openHighPriorityModal(unauthorizedErrorModal, {
          config: { backdrop: true, keyboard: true },
        });
      } else if (
        !error.response ||
        !error.response.data ||
        !['MESSAGE_ERROR', 'VALIDATION_ERROR'].includes(
          error.response.data.status,
        )
      ) {
        // Excludes handling 'MESSAGE_ERROR' and 'VALIDATION_ERROR' types here.
        await modalManager.openRegisteredHighPriorityModal(
          'modal-general-error',
          {
            config: { backdrop: true, keyboard: true },
          },
        );
      }

      if (!consentStore.hasConsent) {
        await modalManager.openUniqueModal(consentModal);
      }

      return Promise.reject(error);
    },
  );

  useHead({
    title: () =>
      translations.has(route.meta.title)
        ? `${translations.getOne(
            route.meta.title,
            'MP',
          )} - ${translations.getOne('meta_title_text_cathay_pacific')}`
        : `${translations.getOne('meta_title_text_cathay_pacific')}`,
  });
</script>

<template>
  <RouterView />

  <Teleport to="body">
    <modal-manager-component />
  </Teleport>
</template>

<style lang="scss" scoped></style>
