import { GuardAbstraction } from 'vue-guarded-router';
import { useAuthStore } from '@/stores/AuthStore';

export default class AuthGuard extends GuardAbstraction {
  isApplied(to) {
    return to.meta.requiresAuth && !useAuthStore().isLoggedIn;
  }

  handle(to, from, next) {
    next({ name: 'AuthenticationView', params: to.params });
  }
}
