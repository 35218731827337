import { defineStore } from 'pinia';
import { useApi, getServices } from 'api';

export const useMainStore = defineStore('main', {
  state: () => ({
    translations: null,
    issuerCountries: null,
  }),

  actions: {
    async fetchTranslations() {
      // Only fetch once
      if (this.translations) {
        return;
      }

      const api = useApi();
      const { GetTranslationsService } = getServices();

      let res = null;
      await api
        .call(GetTranslationsService, { skipInterceptor: true })
        .then((response) => {
          res = response;
        });

      this.translations = res.payload;
    },
    async fetchIssuerCountries() {
      // Only fetch once
      if (this.issuerCountries) {
        return;
      }

      const api = useApi();
      const { GetIssuerCountriesService } = getServices();

      let res = null;
      await api.call(GetIssuerCountriesService).then((response) => {
        res = response;
      });

      this.issuerCountries = res.payload;
    },
  },
  getters: {
    getTranslations: (state) => state.translations,
    getIssuerCountries: (state) => state.issuerCountries,
  },
  persist: {
    enabled: true,
    strategies: [
      {
        paths: ['translations', 'issuerCountries'],
      },
    ],
  },
});
