import ServiceAbstraction from '../service-abstraction.js';

class GetProfileService extends ServiceAbstraction {
  static identifier = 'GetProfileService';
  static baseUrlPath = '/profile';

  constructor(httpClient, configManager, options = {}) {
    super(httpClient, configManager);
    this.options = options;
  }

  inputParams() {
    return this.options;
  }

  async doCall(options) {
    return super._httpLayer().get(options);
  }

  output(response) {
    return response;
  }
}

export default GetProfileService;
