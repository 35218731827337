<script setup>
  import { VueRecaptcha } from 'vue-recaptcha';
  import { inject, ref } from 'vue';
  const translations = inject('translations');
  const modalManager = inject('modalManager');

  const recaptcha = ref(null);
  const siteKey = ref(import.meta.env.VITE_CAPTCHA_SITE_KEY);

  function execute() {
    recaptcha.value.reset();
    recaptcha.value.execute();
  }

  function expiredMethod() {
    recaptcha.value.reset();
  }

  async function errorMethod() {
    await modalManager.openRegisteredHighPriorityModal('modal-general-error', {
      config: { backdrop: true, keyboard: true },
      props: {
        title: translations.getOne(
          'invisible_captcha_error_title',
          'Captcha error',
        ),
        message: translations.getOne(
          'invisible_captcha_error_message',
          'An error occurred while trying to verify captcha',
        ),
      },
    });
  }

  defineExpose({ execute });
</script>

<template>
  <vue-recaptcha
    ref="recaptcha"
    :sitekey="siteKey"
    badge="badge"
    size="invisible"
    @expired="expiredMethod"
    @error="errorMethod"
  />
</template>

<style lang="scss">
  .grecaptcha-badge {
    z-index: 50;
  }
</style>
