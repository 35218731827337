<script setup></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    fill="currentColor"
    aria-hidden="true"
  >
    <title>
      {{ $translation('aria_label_icon_checkmark_fill', 'Success') }}
    </title>
    <g stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
      <rect
        fill-opacity="0"
        fill="#FFFFFF"
        x="0"
        y="0"
        width="24"
        height="24"
      />
      <path
        d="M17.6292016,9.49828075 L11.2469009,15.8805814 C11.0516409,16.0758414 10.7350609,16.0758414 10.5398009,15.8805814 L6.37080044,11.711581 C6.29268043,11.633481 6.29268043,11.506841 6.37080044,11.4287209 L6.44150044,11.3580209 C6.91014049,10.8894009 7.66994057,10.8894009 8.13856061,11.3580209 L10.8933609,14.1128212 L15.8614214,9.14472071 C16.3300614,8.67610067 17.0898615,8.67610067 17.5584816,9.14472071 L17.6292016,9.21542072 C17.7073016,9.29354073 17.7073016,9.42018074 17.6292016,9.49828075 M12,2 C6.47714045,2 2,6.47714045 2,12 C2,17.5228616 6.47714045,22 12,22 C17.5228416,22 22,17.5228616 22,12 C22,6.47714045 17.5228416,2 12,2"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped></style>
