import { GuardAbstraction } from 'vue-guarded-router';
import { useRouteStore } from '@/stores/RouteStore';

export default class PreviousRouteGuard extends GuardAbstraction {
  isApplied() {
    return true;
  }

  handle(to, from) {
    const routeStore = useRouteStore();

    if (from && from.name) {
      routeStore.setPreviousRoute(from);
    }
  }
}
