<script setup>
  import { computed, ref, onMounted, inject } from 'vue';
  import { Collapse } from 'bootstrap';
  import emitter from '@/composables/event-bus';

  defineProps({
    title: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  });

  const translations = inject('translations');
  const consentCollapse = ref(null);
  let toggle = ref(false);

  let collapseInstance;

  const toggleDetails = computed(() =>
    toggle.value
      ? translations.getOne(
          'consent_description_label_less_details',
          'View less details',
        )
      : translations.getOne(
          'consent_description_label_more_details',
          'View more details',
        ),
  );
  const flipIcon = computed(() => (toggle.value ? 'flip-icon' : ''));

  // Mounted
  onMounted(() => {
    if (consentCollapse.value) {
      collapseInstance = new Collapse(consentCollapse.value, { toggle: false });
    }

    emitter.$on('collapse::consent-description::close', () => {
      closeCollapse();
    });
  });

  // Methods
  function openCollapse() {
    collapseInstance.show();
    toggle.value = true;
  }

  function closeCollapse() {
    collapseInstance.hide();
    toggle.value = false;
  }

  function handleCollapse() {
    toggle.value ? closeCollapse() : openCollapse();
  }
</script>
<template>
  <section class="consent-description">
    <h2 class="description-title">
      <span>{{ title }}</span>
      <span v-if="selected" class="selected-consent">
        <span class="visually-hidden">
          <!-- Whitespace necessary to ensure proper spacing for screen readers -->
          &nbsp;

          {{
            $translation('aria_label_consent_description_selected', 'Selected')
          }}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          class="checkmark"
          aria-hidden="true"
        >
          <title>
            {{
              $translation(
                'aria_label_consent_description_selected',
                'Selected',
              )
            }}
          </title>
          <rect width="24" height="24" opacity="0" />
          <g transform="translate(-1015 -1201)">
            <path
              d="M11,21A10,10,0,1,1,21,11,10.011,10.011,0,0,1,11,21Z"
              transform="translate(1016 1202)"
              fill="currentColor"
            />
            <path
              data-name="Path 4020"
              d="M1382.951,11861.193l-2.828-2.828a1,1,0,1,1,1.414-1.414l2.121,2.121,4.949-4.949a1,1,0,0,1,1.414,1.414l-5.656,5.656a.995.995,0,0,1-1.414,0Z"
              transform="translate(-357.829 -10644.832)"
              fill="#fff"
            />
          </g>
        </svg>
      </span>
    </h2>

    <p class="description-content">
      <slot />
      <button-link
        icon
        link
        @click="handleCollapse"
        :aria-expanded="toggle"
        class="details-activator"
      >
        {{ toggleDetails }}
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-down"
          viewBox="0 0 16 16"
          :class="flipIcon"
        >
          <path
            fill-rule="evenodd"
            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </button-link>
    </p>
    <div class="collapse" ref="consentCollapse">
      <slot name="collapse" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .consent-description {
    padding: 15px 0 0 0;

    .description-title {
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;

      .selected-consent {
        .checkmark {
          height: 27px;
          width: 27px;
          margin-bottom: 4px;
          color: $primary-color;
        }
      }
    }

    .description-content {
      .details-activator {
        gap: 6px;
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
        margin-block-end: 6px;
        display: inline;
      }
    }
  }

  .flip-icon {
    transform: scaleY(-1);
  }
</style>
