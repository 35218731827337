import CreditCardSchemeAbstraction from '../credit-card-scheme-abstraction.js';

class VisaDankort extends CreditCardSchemeAbstraction {
  static schemeName = 'visa-dankort';

  constructor(
    inputMask = '#### #### #### ####',
    cardNumberLength = 16,
    inputMaskDelimiter = ' '
  ) {
    super(inputMask, cardNumberLength, inputMaskDelimiter);
  }

  liveDetectScheme(partialCardNumber) {
    return /^(4|45|457|4571\d{0,12})$/.test(partialCardNumber);
  }

  detectScheme(cardNumber) {
    return /^4571\d{12}$/.test(cardNumber);
  }
}

export default VisaDankort;
