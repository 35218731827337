<script setup></script>

<template>
  <base-modal
    id="unauthorized-error"
    :title="$translation('the_modal_unauthorized_title', 'Session Timed Out')"
    :text="
      $translation(
        'the_modal_unauthorized_text',
        'Your session has timed out. Please try to log in again.',
      )
    "
  >
    <template v-slot:footer="{ close }">
      <button-primary @click="close">
        {{ $translation('the_modal_unauthorized_close_button', 'Ok') }}
      </button-primary>
    </template>
  </base-modal>
</template>

<style lang="scss" scoped></style>
