<script setup>
  import { ref, computed, inject } from 'vue';
  import { useConsentStore } from '@/stores/ConsentStore';
  import emitter from '@/composables/event-bus';

  const consentStore = useConsentStore();
  const translations = inject('translations');

  const consentModal = ref(null);

  const storage = computed(() => [
    {
      title: translations.getOne(
        'the_modal_consent_storage_title_cookies',
        'Cookies',
      ),
      name: '_GRECAPTCHA',
      service: 'Google',
    },
    {
      title: translations.getOne(
        'the_modal_consent_storage_title_local_storage',
        'Local Storage',
      ),
      name: 'consent',
      service: translations.getOne(
        'the_modal_consent_service_provider',
        'Cathay Pacific',
      ),
    },
    {
      name: '_grecaptcha',
      service: 'Google',
    },
    {
      title: translations.getOne(
        'the_modal_consent_storage_title_session_storage',
        'Session Storage',
      ),
      name: 'locale',
      service: translations.getOne(
        'the_modal_consent_service_provider',
        'Cathay Pacific',
      ),
    },
    {
      name: 'auth',
      service: translations.getOne(
        'the_modal_consent_service_provider',
        'Cathay Pacific',
      ),
    },
    {
      name: 'main',
      service: translations.getOne(
        'the_modal_consent_service_provider',
        'Cathay Pacific',
      ),
    },
  ]);

  function handleFullConsent(close) {
    consentStore.setConsentChoice(true);
    close();
  }

  function handleNecessaryConsent(close) {
    consentStore.setConsentChoice(false);
    close();
  }

  function onConsentModalHidden() {
    emitter.$emit('collapse::consent-description::close');
  }
</script>

<template>
  <base-modal
    ref="consentModal"
    id="consent-modal"
    max-width="730px"
    @on-hidden="onConsentModalHidden"
  >
    <template v-slot:custom-content="{ close }">
      <div class="modal-content consent-modal-content">
        <!-- Consent Body -->
        <div class="modal-body consent-modal-body" id="consent-modal-desc">
          <h1 class="consent-title" id="consent-modal-title">
            {{ $translation('the_modal_consent_title', 'Cookies Settings') }}
          </h1>
          <p>
            {{
              $translation(
                'the_modal_consent_text',
                'We use third-party services to collect necessary information (browser information, IP, tracking, etc.), which is used to measure website traffic as well as do analysis work, which ultimately helps to make the website more usable and give you a better experience. Therefore, we need your consent to continue to make the website experience better.',
              )
            }}
          </p>

          <!-- Full Consent -->
          <consent-description
            :title="
              $translation(
                'the_modal_consent_full_consent_title',
                'Accept all cookies',
              )
            "
            :selected="consentStore.isFullConsent"
          >
            {{
              $translation(
                'the_modal_consent_full_consent_text',
                'Marketing cookies are placed by us and third-party partners to help deliver more relevant content and offers to you, based on your interests. Functional cookies allow us to remember your choices (such as your choice of country and language preference) when you return to our website and app.',
              )
            }}
            <template v-slot:collapse>
              <consent-cookie-detail
                :content="{
                  title: $translation(
                    'the_modal_consent_storage_title_cookies',
                    'Cookies',
                  ),
                }"
              >
                <p>
                  {{
                    $translation(
                      'the_modal_consent_no_cookies',
                      'No specific cookies.',
                    )
                  }}
                </p>
              </consent-cookie-detail>
            </template>
          </consent-description>

          <!-- Necessary Consent -->
          <consent-description
            :title="
              $translation(
                'the_modal_consent_necessary_consent_title',
                'Necessary cookies',
              )
            "
            :selected="consentStore.isNecessaryConsent"
          >
            {{
              $translation(
                'the_modal_consent_necessary_consent_text',
                'Strictly necessary cookies are required for the website to function properly, while performance cookies enable us to record and optimize our website performance without storing your personal data. These cookies cannot be turned off.',
              )
            }}
            <template v-slot:collapse>
              <consent-cookie-detail
                v-for="(item, index) in storage"
                :key="index"
                :content="item"
              />
            </template>
          </consent-description>
        </div>

        <!-- Consent footer -->
        <div class="modal-footer consent-modal-footer">
          <div class="footer-content">
            <button-secondary
              class="consent-button"
              medium-size
              @click="handleNecessaryConsent(close)"
            >
              {{
                $translation(
                  'the_modal_consent_button_label_necessary_consent',
                  'Reject all',
                )
              }}
            </button-secondary>
            <button-primary
              class="consent-button"
              medium-size
              @click="handleFullConsent(close)"
            >
              {{
                $translation(
                  'the_modal_consent_button_label_full_consent',
                  'Agree to all',
                )
              }}
            </button-primary>
          </div>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<style lang="scss" scoped>
  .consent-modal-content {
    border-radius: 0;

    .consent-modal-body {
      padding-block-start: 32px;
      padding-inline: 24px;
    }

    .consent-modal-footer {
      padding: 0;
      border: 0;

      .footer-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-block-end: 24px;
        padding-inline: 24px;
        gap: 16px;

        @include md-breakpoint {
          flex-direction: row;
        }
      }
    }
  }
</style>
