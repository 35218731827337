import ServiceAbstraction from '../service-abstraction.js';

class GetIssuerCountriesService extends ServiceAbstraction {
  static identifier = 'GetIssuerCountriesService';
  static baseUrlPath = '/issuer-countries';

  constructor(httpClient, configManager, options = {}) {
    super(httpClient, configManager);
    this.options = options;
  }

  inputParams() {
    return this.options;
  }

  async doCall(options) {
    return super._httpLayer().get(options);
  }

  output(response) {
    return response;
  }
}

export default GetIssuerCountriesService;
