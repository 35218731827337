<script setup>
  import { computed } from 'vue';
  import { HEADER_URLS } from '@/configs/url-config.js';
  import { useUtility } from '@/composables/use-utility.js';
  const { getLocaleBasedUrl } = useUtility();

  const headerUrls = HEADER_URLS;

  const localeUrl = computed(() =>
    getLocaleBasedUrl(headerUrls.account_overview_link),
  );
</script>

<template>
  <link-external :href="localeUrl" target="_self" :isAccessibilityIconVisible="false">
    {{ $translation('link_account_overview_link_label', 'Account overview') }}
  </link-external>
</template>

<style lang="scss" scoped></style>
