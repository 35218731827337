<script setup>
  import { useAuthStore } from '@/stores/AuthStore';
  import { inject } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  const loaderHandler = inject('loaderHandler');

  const route = useRoute();
  const router = useRouter();

  async function handleSignOut() {
    let signOutUrl = null;

    if (loaderHandler.isLoading('authStore::loader::signOut')) {
      return;
    }

    try {
      signOutUrl = await useAuthStore().signOut();
    } catch (e) {
      //
    }

    if (signOutUrl) {
      window.location.href = signOutUrl;
      return;
    }

    await router.push({
      name: 'AccessDenied',
      params: route.params,
    });
  }
</script>

<template>
  <button-link @click="handleSignOut">
    {{ $translation('button_sign_out_label', 'Sign out') }}
  </button-link>
</template>

<style lang="scss" scoped></style>
