<script setup>
  import { computed, inject } from 'vue';
  import { useAuthStore } from '@/stores/AuthStore';
  import { storeToRefs } from 'pinia';
  import { useUtility } from '@/composables/use-utility.js';
  const { getDynamicComponent } = useUtility();

  const translations = inject('translations');

  const props = defineProps({
    iconId: String,
  });

  const authStore = useAuthStore();
  const { getUser } = storeToRefs(authStore);

  const tierLevel = computed(() =>
    getUser.value.tierCode
      ? {
          name: memberTierKey.value,
          component: getDynamicComponent(`IconMember${getUser.value.tierCode}`),
        }
      : {},
  );

  const memberTierKey = computed(() =>
    translations.has(`member_tier_card_${getUser.value.tierCode}`)
      ? translations.getOne(
          `member_tier_card_${getUser.value.tierCode}`,
          getUser.value.tierName,
        )
      : null,
  );

  const isTierNameAriaHidden = computed(() => !!props.iconId);
</script>

<template>
  <component
    :icon-id="iconId"
    :is="tierLevel.component"
    v-if="iconId"
    class="card-tier-icon"
  />
  <slot :number="getUser.externalId">
    <span :aria-hidden="isTierNameAriaHidden">{{ tierLevel.name }}</span>
    <span class="member-border" aria-hidden="true" v-if="tierLevel.name">
      |
    </span>
    <span class="member-number">{{ getUser.externalId }}</span>
  </slot>
</template>

<style lang="scss" scoped>
  .card-tier-icon {
    width: 30px;
    height: 20px;
  }
  .member-border {
    margin-inline: 8px;
    color: $border-color;
  }
</style>
