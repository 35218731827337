import createPhaseNavigationGuard from './create-phase-navigation-guard.js';
import { NAVIGATION_PHASES } from './vue-router.js';

/**
 * Attaches per-route navigation guards to routes based on the meta property of each route.
 * @param {object} options - Options containing routes with associated navigation guards.
 * @returns {Array<Object>} - An array of routes with attached per-route navigation guards.
 */
const attachPerRouteNavigationGuards = (options = {}) => {
  // Extracting routes from options (or providing an empty array if not present).
  const routes = options?.routes || [];

  const attachGuardToRoute = (route) => {
    // Extracting navigation guards associated with the specific phase (e.g., 'beforeEnter').
    const { navigationGuards = {} } = route;

    // Iterating over each specified phase.
    Object.keys(navigationGuards).forEach((phase) => {
      // Checking if the specified phase is 'beforeEnter'.
      if (phase === NAVIGATION_PHASES.BEFORE_ENTER) {

        // Extracting the guards associated with 'beforeEnter' phase.
        const guards = navigationGuards[phase];

        // Creating a 'beforeEnter' navigation guard for the route.
        route[phase] = [];
        for (const guard of guards) {
          route[phase].push(createPhaseNavigationGuard(guard));
        }
      }
    });

    // If the route has children, attach guards to them recursively.
    if (route.children) {
      route.children = route.children.map(attachGuardToRoute);
    }

    return route; // Returning the modified route object.
  };

  // Apply guards to each route.
  return routes.map(attachGuardToRoute);
};

export default attachPerRouteNavigationGuards;
