import * as exceptions from './src/exceptions/exceptions.js';
import DependencyContainer from './src/dependency-container.js';
import ConfigManager from './src/config-manager.js';
import ServiceAbstraction from './src/service-abstraction.js';
import mocksResponseHelperSuccess from './src/response-helpers/response-success.js';
import mocksResponseHelperError from './src/response-helpers/response-error.js';
import HttpLayerAxios from './src/http-layer-axios.js';

// Define global containers and handlers.
const container = new DependencyContainer();
const configManager = new ConfigManager();

// Method to initialize the API with the specified configuration.
// It sets the initial API and HTTP layer configuration based on the provided 'config' object.
const createApi = (config = {}) => {
  configManager.setApiConfig = config;
  configManager.setHttpLayerConfig = config;

  // Inject the HttpLayer with config.
  const httpLayer = new HttpLayerAxios(configManager.getHttpLayerConfig);
  injectHttpLayer(httpLayer);
};

/**
 * Factory function to create an API interaction object.
 * @param {InstanceType} httpLayer - The HTTP layer implementation instance.
 * @param {InstanceType} serviceProvider - The service provider implementation instance.
 * @param {InstanceType} mocksServiceProvider - The mock service provider implementation instance.
 * @param {Object} config - The configuration object for the API.
 * @returns {Object} - An object with a 'call' method to invoke API methods.
 */
const useApi = (
  httpLayer = null,
  serviceProvider = null,
  mocksServiceProvider = null,
  config = null,
) => {
  if (serviceProvider) {
    injectServiceProvider(serviceProvider);
  }

  if (mocksServiceProvider) {
    injectMocksServiceProvider(mocksServiceProvider);
  }

  if (config) {
    configManager.setApiConfig = config;
    configManager.setHttpLayerConfig = config;
  }

  if (httpLayer) {
    injectHttpLayer(httpLayer);
  }

  const call = async (MethodName, options = {}) => {
    try {
      return new MethodName(
        container.getHttpLayer,
        configManager,
        options,
      )._invoke();
    } catch (error) {
      console.error(`Error calling API service:`, error);
      throw new Error(`Error calling API service: ${error.message}`);
    }
  };

  return { call };
};

// Method to add headers to the HTTP layer configuration.
// The 'headers' parameter should be an object containing the headers to be added.
// It updates the existing configuration by merging the new headers with any existing ones.
const addHeaders = (headers) => {
  const config = { ...configManager.getHttpLayerConfig };

  configManager.setHttpLayerConfig = {
    headers: { ...config.httpLayer?.headers, ...headers },
    ...config.httpLayer,
  };
};

// This method removes a specific header from the HTTP layer configuration options.
// It first retrieves the current HTTP layer configuration from 'configManager'.
// If the configuration exists and has headers defined, it deletes the specified 'headerPropertyName' if found.
// The updated configuration is then saved back to 'configManager'.
const removeHeader = (headerPropertyName) => {
  const config = configManager.getHttpLayerConfig || {};

  if (config.httpLayer && config.httpLayer.headers) {
    delete config.httpLayer.headers[headerPropertyName];

    configManager.setHttpLayerConfig = config;
  }
};

/**
 * Sets a service provider instance in the global dependency container.
 * @param {InstanceType} serviceProvider - The service provider instance.
 */
const injectServiceProvider = (serviceProvider) => {
  container.setServiceProvider = serviceProvider;
};

/**
 * Sets a mock service provider instance in the global dependency container.
 * @param {InstanceType} mocksServiceProvider - The mock service provider instance.
 */
const injectMocksServiceProvider = (mocksServiceProvider) => {
  container.setMocksServiceProvider = mocksServiceProvider;
};

/**
 * Sets an HTTP layer implementation in the global dependency container.
 * @param {InstanceType} httpLayer - The HTTP layer instance.
 */
const injectHttpLayer = (httpLayer) => {
  container.setHttpLayer = httpLayer;
};

// The getServices function retrieves a list of service methods based on the configuration of the API.
// It checks if the API is configured to use mock data and calls dependencyContainer.getServices accordingly.
// The result is a mapping of unique identifiers to their respective service methods.
const getServices = () => {
  const isMocked = configManager.getApiConfig?.mocks === true;
  const methods = container.getServices(isMocked);

  // Reduce operation is used to construct an object where each service method is associated with its unique identifier.
  return methods.reduce((obj, method) => {
    obj[method.getIdentifier()] = method;
    return obj;
  }, {});
};

// Method to retrieve API supported exceptions for standardization.
const getExceptions = () => {
  return exceptions;
};

/**
 * Provides access to the HTTP layer instance.
 * This function fetches the HTTP layer from a container's `getHttpLayer` method,
 * allowing centralized management of the HTTP layer throughout the application.
 *
 * @returns {InstanceType} The HTTP layer instance from the container.
 */
const getHttpLayer = () => {
  return container.getHttpLayer;
};

/**
 * Provides access to the HTTP client instance.
 * This function fetches the HTTP client from a container's `getHttpLayer` method,
 * allowing centralized management of the HTTP client throughout the application.
 *
 * @returns {InstanceType} The HTTP client instance from the container.
 */
const getHttpClient = () => {
  return container.getHttpLayer?.httpClient;
};

const getInterceptors = () => {
  return container.getHttpLayer?.interceptors;
};

export {
  createApi, // Method to initialize the API with specified config.
  useApi, // Factory function to create an API interaction object.
  getServices, // Method to retrieve a list of service methods.
  getExceptions, // Method to get API supported exceptions for standardization.
  addHeaders, // Method to add headers to the instance options.
  removeHeader, // Method to remove a specific header from the instance options.
  injectServiceProvider, // Method to inject a service provider into the global dependency container.
  injectMocksServiceProvider, // Method to inject a mock service provider into the global dependency container.
  injectHttpLayer, // Method to inject an HTTP layer into the global dependency container.
  ServiceAbstraction, // Factory function to obtain the ServiceAbstraction class, facilitating the creation of services.
  mocksResponseHelperSuccess, // Method to assist in generating mock responses.
  mocksResponseHelperError, // Method to assist in generating mock responses.
  getHttpLayer, // Method to get the HttpLayer instance.
  getInterceptors, // Method to get the HttpLayer interceptors.
  getHttpClient, // Method to get the HttpClient instance.
};
