import ServiceAbstraction from '../service-abstraction.js';

class SignOutService extends ServiceAbstraction {
  static identifier = 'SignOutService';
  static baseUrlPath = '/logout';

  constructor(httpClient, configManager, options = {}) {
    super(httpClient, configManager);
    this.options = options;
  }

  inputParams() {
    return this.options;
  }

  async doCall(options) {
    return super._httpLayer().get(options);
  }

  output(response) {
    return response;
  }
}

export default SignOutService;
