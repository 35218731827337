import { reactive, computed, readonly } from 'vue';

export function useModalConfig(globalOptions = {}) {
  // Reactive state for global and individual modal configurations.
  const state = reactive({
    globalConfig: globalOptions.config || {}, // Global options and default configuration for modals.
    individualConfigs: new Map(),
  });

  // Method to merge global and individual modal configurations.
  function getMergedConfig(modalId) {
    const individualConfig = state.individualConfigs.get(modalId) || {};
    return { ...state.globalConfig, ...individualConfig };
  }

  // Method to set or update individual modal configuration.
  function setConfig(modalId, config) {
    state.individualConfigs.set(modalId, config);
  }

  function removeConfig(modalId) {
    if (state.individualConfigs.has(modalId)) {
      state.individualConfigs.delete(modalId);
    }
  }

  function clearAllIndividualConfigs() {
    state.individualConfigs.clear();
  }

  return {
    getMergedConfig,
    setConfig,
    removeConfig,
    clearAllIndividualConfigs,
    globalConfig: readonly(state.globalConfig),
    individualConfigs: readonly(state.individualConfigs),
  };
}
