<script setup></script>

<template>
  <base-button class="button-remove">
    <slot />
  </base-button>
</template>

<style lang="scss" scoped>
  .button-remove {
    background: $tertiary-color;
    color: $quaternary-color;
    justify-content: center;

    &.loading,
    &:hover,
    &:focus,
    &:active {
      background: $tertiary-color-hover;
      color: $quaternary-color;
      text-decoration: underline;
    }
  }
</style>
