import useModalManager from './src/modal-manager.js';
import ModalManagerComponent from './src/components/ModalManager.vue';

export default {
  install: (app, options = {}) => {
    // Check for Vue 3 compatibility.
    const [majorVersion] = app.version.split('.').map(Number);
    if (majorVersion < 3) {
      console.warn('This plugin requires Vue 3');
      return;
    }

    // Initialize ModalManager with the provided options.
    const modalManager = useModalManager(options);

    // Register the ModalManagerComponent globally in the Vue application.
    app.component('ModalManagerComponent', ModalManagerComponent);

    // Make the ModalManager available to all components.
    app.provide('modalManager', modalManager);

    // Attach the ModalManager to the global properties,
    app.config.globalProperties.$modalManager = modalManager;
  },
};
