<script setup>
  import { ref, watchEffect, computed } from 'vue';

  const props = defineProps({
    value: String,
    ariaLive: {
      type: String,
      default: 'assertive',
      validator: (value) => {
        return ['assertive', 'polite', 'off'].indexOf(value) !== -1;
      },
    },
    hidden: {
      type: Boolean,
      default: true,
    },
  });

  const textToRead = ref([]);

  const isVisuallyHidden = computed(() =>
    props.hidden ? 'visually-hidden' : null,
  );

  watchEffect(() => {
    handleTextToRead(props.value);
  });

  function handleTextToRead(text) {
    textToRead.value = [];
    if (text) {
      textToRead.value.push(text);
    }
  }
</script>

<template>
  <div
    :class="[isVisuallyHidden]"
    :aria-live="ariaLive"
    role="alert"
    aria-relevant="additions"
    aria-atomic="true"
  >
    <slot :textToRead="textToRead">
      <p v-for="(text, index) in textToRead" :key="index">{{ text }}</p>
    </slot>
  </div>
</template>

<style lang="scss" scoped></style>
