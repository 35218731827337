<script setup></script>

<template>
  <link-internal
    href="#main"
    class="skip-links link-button-primary visually-hidden-focusable"
  >
    {{ $translation('aria_label_skip_links', 'Skip Links') }}
  </link-internal>
</template>

<style lang="scss" scoped>
  .skip-links {
    display: none;
  }

  @media screen and (hover: hover) and (pointer: fine) {
    .skip-links {
      position: absolute;
      top: 2px;
      left: 2px;
      z-index: 1030;
      display: none;
    }

    .link-button-primary {
      background: $primary-color;
      color: $quaternary-color;
      padding: 18px 24px;
      width: 100%;
      text-decoration: none;

      display: flex;
      justify-content: center;
      gap: 4px;

      font-size: 1em;
      line-height: 24px;
      text-align: left;
      font-family: $font-regular-text;

      min-width: 216px;
      width: max-content;

      &:hover,
      &:focus,
      &:active {
        background: $primary-color-hover;
        color: $quaternary-color;
        text-decoration: underline;
      }
    }

    html[dir='rtl'] {
      .skip-links {
        right: 2px;
      }
    }
  }
</style>
