<script setup></script>

<template>
  <base-modal class="cardinal-modal">
    <cardinal-step-up />
  </base-modal>
</template>

<style lang="scss" scoped>
  .cardinal-modal {
    :deep(.base-modal-default-width) {
      max-width: 500px !important;
    }

    :deep(.base-modal-content) {
      background-color: transparent;
      border: none;
    }
  }
</style>
