import EventBus from './src/event-bus.js';

/**
 * @returns {Object} - An object with an 'EventBus' property that contains the current EventBus instance.
 */
export const useEventBus = () => {
  return {
    EventBus: new EventBus(),
  };
};
