<script setup>
  import { RouterLink } from 'vue-router';

  // Handle keyboard events for the RouterLink component
  const handleKeyDown = (event) => {
    // Handle the space key
    if (event.code === 'Space') {
      event.preventDefault(); // Prevent scrolling when using space button
      event.target.click(); // Trigger the link's click event
    }
  };
</script>

<template>
  <RouterLink class="link-button-primary" @keydown="handleKeyDown">
    <slot />
  </RouterLink>
</template>

<style lang="scss" scoped>
  .link-button-primary {
    background: $primary-color;
    color: $quaternary-color;
    padding: 18px 24px;
    width: 100%;
    text-decoration: none;

    display: flex;
    justify-content: center;
    gap: 4px;

    font-size: 1em;
    line-height: 24px;
    text-align: left;
    font-family: $font-regular-text;

    @include md-breakpoint {
      min-width: 216px;
      width: max-content;
    }

    &:hover,
    &:focus,
    &:active {
      background: $primary-color-hover;
      color: $quaternary-color;
      text-decoration: underline;
    }
  }
</style>
