import createPhaseNavigationGuard from './create-phase-navigation-guard.js';
import { NAVIGATION_PHASES } from './vue-router.js';

/**
 * Attaches global navigation guards to a Vue Router instance based on specified phases.
 * @param {object} vueRouter - The Vue Router instance to which global guards will be attached.
 * @param {object} options - Options containing global navigation guards.
 * @returns {object} - The Vue Router instance with attached global navigation guards.
 */
const attachGlobalNavigationGuards = (vueRouter = {}, options = {}) => {
  // Extracting global navigation guards from options.
  const globalNavigationGuards = options?.globalNavigationGuards || {};

  // Phases to which global guards can be attached.
  const allowedPhases = [
    NAVIGATION_PHASES.BEFORE_EACH,
    NAVIGATION_PHASES.BEFORE_RESOLVE,
    NAVIGATION_PHASES.AFTER_EACH,
  ];

  // Iterating over specified phases.
  Object.keys(globalNavigationGuards).forEach((phase) => {
    // Checking if the specified phase is allowed.
    if (allowedPhases.includes(phase)) {
      // Attaching the navigation guard to the specified phase.
      for (const guard of globalNavigationGuards[phase]) {
        vueRouter[phase](createPhaseNavigationGuard(guard, vueRouter));
      }
    }
  });

  return vueRouter;
};

export default attachGlobalNavigationGuards;
