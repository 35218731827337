<script setup>
  import { useSlots } from 'vue';

  const slots = useSlots();
</script>

<template>
  <div class="alert-info">
    <div class="icon-wrapper" v-if="slots.icon">
      <slot name="icon" />
    </div>
    <p class="alert-label"><slot /></p>
  </div>
</template>

<style lang="scss" scoped>
  .alert-info {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-inline: 16px;
    padding-block: 8px;
    gap: 4px;
    border-inline-start: 2px solid $senary-color;

    .icon-wrapper {
      width: 24px;
      height: 24px;
    }

    .alert-label {
      font-size: 0.875em;
      line-height: 20px;
      font-family: $font-regular-heading;
      margin: 0;
      min-height: 24px;
      max-width: 976px;
    }
  }
</style>
