import { markRaw, nextTick, readonly, reactive, ref } from 'vue';
import { useModalEvents } from './modal-events.js';
import { useModalQueue } from './modal-queue.js';
import { useModalRegistry } from './modal-registry.js';
import { useModalConfig } from './modal-config.js';
import { useBootstrapModal } from './bootstrap-modal.js';
import { useFocusManager } from './focus-manager.js';

const DEFAULT_ACTIVE_MODAL_ID = 'active-modal';

export default function useModalManager(options) {
  const state = reactive({
    activeModal: null, // Currently active modal.
    activeElementRef: ref(null), // Reference to the modal DOM element.
    isProgrammaticClose: false, // Keep track of if it was closed programmatic (closeModal).
  });

  // Use composable.
  const modalEvents = useModalEvents();
  const modalQueue = useModalQueue();
  const modalRegistry = useModalRegistry();
  const modalConfig = useModalConfig(options);
  const bootstrapModal = useBootstrapModal(options);
  const focusManager = useFocusManager(options);

  /**
   * Opens a modal with the given component and options.
   * It handles the instantiation of the modal, managing its state, and applying provided configurations.
   */
  async function openModal(modalComponent, options = {}) {
    // Make modal component raw, to avoid reactive component overhead.
    modalComponent = markRaw(modalComponent);

    // Destructure and set defaults for the options' parameter.
    let {
      props = {},
      config = {},
      eventHandlers = {},
      focusedElementRef = null,
    } = options;

    // Use provided id or static id.
    const modalId = options.id || DEFAULT_ACTIVE_MODAL_ID;
    modalConfig.setConfig(modalId, config);
    config = modalConfig.getMergedConfig(modalId);

    // Set the trigger element reference if provided.
    focusManager.setFocus(focusedElementRef);

    // Queue the modal if another modal is already open.
    if (state.activeModal) {
      modalQueue.enqueueModal(modalComponent, options);
      return;
    }

    // Set the active modal details.
    state.activeModal = {
      id: modalId,
      component: modalComponent,
      props: props,
      eventHandlers: eventHandlers,
    };

    // Wait for the next DOM update to ensure the modal element is mounted.
    await nextTick();

    // Instantiate the Bootstrap modal with the provided configuration.
    bootstrapModal.createModal(state.activeElementRef, config);

    // Handle modal events.
    // Return a promise that resolves when the modal is shown.
    // If bootstraps own close methods are used, we reset the modal manager.
    return new Promise((resolve) => {
      _addModalEventListener('show.bs.modal', async () => {
        await nextTick();
        modalEvents.setEvent(modalId, 'show');
      });

      _addModalEventListener('shown.bs.modal', async () => {
        await nextTick();
        modalEvents.setEvent(modalId, 'shown');
        resolve();
      });

      _addModalEventListener('hide.bs.modal', async () => {
        await nextTick();
        modalEvents.setEvent(modalId, 'hide');
      });

      _addModalEventListener('hidden.bs.modal', async () => {
        await nextTick();
        modalEvents.setEvent(modalId, 'hidden');

        if (!state.isProgrammaticClose) {
          await _handleModalClosed();
        }
      });

      bootstrapModal.showModal();
    });
  }

  /**
   * Opens a high-priority modal. This method ensures that any existing modals,
   * whether currently displayed or queued, are closed and cleared before opening
   * the new high-priority modal. This is useful for situations where the new modal
   * should take immediate precedence over any others.
   */
  async function openHighPriorityModal(modalComponent, options = {}) {
    modalQueue.clearQueue();

    // Check if there is an active modal open. If so, close it.
    if (state.activeModal) {
      await closeModal();
    }

    // Open the new high priority modal using the provided component and options.
    return openModal(modalComponent, options);
  }

  /**
   * Opens a registered modal based on its unique ID.
   */
  async function openRegisteredModal(id, options = {}) {
    const registeredModal = modalRegistry.getRegisteredModal(id);

    if (!registeredModal) {
      return;
    }

    // Create a new options object that includes the modal's ID.
    const optionsWithId = { id, ...options };
    await openModal(registeredModal, optionsWithId);
  }

  /**
   * Opens a high-priority registered modal based on its unique ID.
   */
  async function openRegisteredHighPriorityModal(id, options = {}) {
    const registeredModal = modalRegistry.getRegisteredModal(id);

    if (!registeredModal) {
      return;
    }

    // Create a new options object that includes the modal's ID.
    const optionsWithId = { id, ...options };
    await openHighPriorityModal(registeredModal, optionsWithId);
  }

  /**
   * Opens the specified modal if it’s not already active or queued, ensuring only one instance is managed.
   */
  async function openUniqueModal(modalComponent, options = {}) {
    const isAlreadyQueued = modalQueue.queue.some(
      (queuedModal) => queuedModal.modalComponent === modalComponent,
    );

    const isAlreadyActive =
      state.activeModal && state.activeModal.component === modalComponent;

    // Check if the modal component is already in the queue or active.
    if (isAlreadyQueued || isAlreadyActive) {
      return;
    }

    await openModal(modalComponent, options);
  }

  /**
   * Closes the currently active modal. This function handles the closing of the modal
   * in a controlled manner, ensuring any necessary cleanup and focus management is done.
   * If there are any modals in the queue (waiting to be opened), it opens the next one.
   */
  async function closeModal() {
    return new Promise((resolve) => {
      if (state.activeModal) {
        state.isProgrammaticClose = true;

        _addModalEventListener('hidden.bs.modal', async () => {
          await _handleModalClosed();
          state.isProgrammaticClose = false;
          resolve();
        });

        bootstrapModal.hideModal();
      } else {
        resolve();
      }
    });
  }

  /**
   * Sets the reference to the active modal element.
   * This is used to keep a reference to the current modal's DOM element,
   * allowing for direct manipulation or access to the modal element.
   */
  function _setActiveElement(ref) {
    state.activeElementRef = ref; // Set the active modal element reference.
  }

  /**
   * Resets the active modal's state.
   * This includes setting the active modal to null, clearing the modal element reference,
   * resetting the Bootstrap modal instance, and restoring the default configuration.
   */
  function _resetActiveModal() {
    state.activeModal = null;
    _setActiveElement(null);
    bootstrapModal.disposeModal();
    modalConfig.clearAllIndividualConfigs();
  }

  /**
   * Create boostrap modal event listeners.
   */
  function _addModalEventListener(
    eventName,
    handler,
    options = { once: true },
  ) {
    state.activeElementRef.addEventListener(eventName, handler, options);
  }

  /**
   * Handle modal closed.
   */
  async function _handleModalClosed() {
    // Reset the active modal state.
    _resetActiveModal();

    // Focus element if present.
    // Modal focus element is not reset, due to the focus element still needs to be focused on backend error.
    focusManager.restoreFocus();

    // If there are modals in the queue, open the next one.
    const nextModal = modalQueue.dequeueModal();
    if (nextModal) {
      await openModal(nextModal.modalComponent, nextModal.options);
    }

    return new Promise((resolve) => {
      resolve();
    });
  }

  return {
    state: readonly(state),
    openModal,
    openHighPriorityModal,
    openRegisteredModal,
    openRegisteredHighPriorityModal,
    openUniqueModal,
    closeModal,
    _setActiveElement,
    registerModal: modalRegistry.registerModal,
    unregisterModal: modalRegistry.unregisterModal,
    registeredModals: modalRegistry.registeredModals,
    clearQueue: modalQueue.clearQueue,
    watchModalEvent: modalEvents.watchEvent,
    events: modalEvents.allEvents,
    globalConfig: modalConfig.globalConfig,
    individualConfigs: modalConfig.individualConfigs,
    modalInstance: bootstrapModal.modalInstance,
    focusedElementRef: focusManager.focusedElementRef,
  };
}
