import { reactive, watch, computed } from 'vue';

export function useModalEvents() {
  const events = reactive(new Map()); // Keeps track of modal events.

  function setEvent(modalId, eventName) {
    events.set(modalId, eventName);
  }

  function clearEvent(modalId) {
    events.delete(modalId);
  }

  /**
   * Sets up a watcher for a specific modal's events.
   * This function allows components to react to state changes of a specific modal.
   */
  function watchEvent(modalId, callback) {
    watch(() => events.get(modalId), callback);
  }

  /**
   * A computed property that provides a view of all events for currently active modal.
   */
  const allEvents = computed(() => {
    let all = {};

    events.forEach((value, key) => {
      all[key] = value;
    });

    return all;
  });

  return {
    setEvent,
    clearEvent,
    watchEvent,
    allEvents,
  };
}
