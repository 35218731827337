export default function ResponseError(
  message = 'Error',
  errors = [],
  $statusCode = 400
) {
  return {
    success: false,
    statusCode: $statusCode,
    status: message,
    payload: null,
    errors: errors,
  };
}
