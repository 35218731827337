import { defineStore } from 'pinia';
import { useUserStore } from '@/stores/UserStore';
import { useApi, getServices, addHeaders } from 'api';
import { useLocaleStore } from '@/stores/LocaleStore';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    accessToken: null,

    user: {
      externalId: null,
      tierName: null,
      tierCode: null,
      hasValidEmail: false,
      activeLinkedPaymentCards: false,
      activeLinkedPaymentCardsCount: 0,
      everLinkedPaymentCard: false,
      maxCardsReached: false,
      maxCardsCount: 0,
    },

    authenticationInProgress: false,
    unauthorizedError: false,
  }),

  actions: {
    async auth() {
      const api = useApi();
      const { AuthTokenService } = getServices();

      const options = {
        params: {
          locale: useLocaleStore().getLocale,
        },
      };

      let res = null;
      await api.call(AuthTokenService, options).then((response) => {
        res = response;
      });

      return res.url;
    },

    async validateAuth() {
      const api = useApi();
      const { ValidateAuthService } = getServices();

      const options = {
        params: {
          locale: useLocaleStore().getLocale,
        },
      };

      let res = null;
      await api.call(ValidateAuthService, options).then((response) => {
        res = response;
      });

      return res.url;
    },

    async verifyToken(args) {
      const loaderKey = 'authStore::loader::verifyToken';

      if (this.isLoading(loaderKey)) {
        return;
      }

      this.startLoader(loaderKey);

      const api = useApi();
      const { VerifyTokenService } = getServices();

      const options = {
        params: {
          code: args.code,
        },
      };

      let res = null;
      await api.call(VerifyTokenService, options).then((response) => {
        res = response;
      });

      this.accessToken = res.payload.token;
      this.setTokenGlobalHeaders();

      await this.fetchProfile();

      this.stopLoader(loaderKey);
    },

    async fetchProfile() {
      const loaderKey = 'authStore::loader::fetchProfile';

      if (this.isLoading(loaderKey)) {
        return;
      }

      this.startLoader(loaderKey);

      const api = useApi();
      const { GetProfileService } = getServices();

      let res = null;
      await api.call(GetProfileService).then((response) => {
        res = response;
      });

      this.stopLoader(loaderKey);

      this.user.externalId = res.payload.external_id;
      this.user.tierCode = res.payload.tier_code;
      this.user.tierName = res.payload.tier_name;
      this.user.activeLinkedPaymentCards =
        res.payload.active_linked_payment_cards;
      this.user.activeLinkedPaymentCardsCount =
        res.payload.active_linked_payment_cards_count;
      this.user.everLinkedPaymentCard = res.payload.ever_linked_payment_card;
      this.user.maxCardsReached = res.payload.max_cards_reached;
      this.user.maxCardsCount = res.payload.max_cards_count;
      this.user.hasValidEmail = res.payload.has_valid_email;
    },

    async signOut() {
      const loaderKey = 'authStore::loader::signOut';

      if (this.isLoading(loaderKey)) {
        return;
      }

      this.startLoader(loaderKey);

      const api = useApi();
      const { SignOutService } = getServices();

      let res = null;
      await api
        .call(SignOutService)
        .then((response) => {
          res = response;
        })
        .finally(() => {
          this.killAuth();
        });

      this.stopLoader(loaderKey);

      return res.url;
    },

    setTokenGlobalHeaders() {
      if (!this.getToken) {
        return;
      }

      // Set Bearer token (used for all auth request)
      addHeaders({ common: { Authorization: 'Bearer ' + this.getToken } });
    },

    killAuth() {
      this.$reset();
      useUserStore().$reset();
    },

    startLoader(key) {
      this.$loaderHandler.start(key);
    },

    stopLoader(key) {
      this.$loaderHandler.stop(key);
    },

    startAuthenticationProcess() {
      this.authenticationInProgress = true;
    },

    stopAuthenticationProcess() {
      this.authenticationInProgress = false;
    },

    isLoading(key) {
      return this.$loaderHandler.isLoading(key);
    },

    setUnauthorizedError() {
      this.unauthorizedError = true;
    },

    resetUnauthorizedError() {
      this.unauthorizedError = false;
    },
  },

  getters: {
    getToken: (state) => state.accessToken,
    isLoggedIn: (state) => state.accessToken !== null,
    getUser: (state) => state.user,
    hasActiveLinkedPaymentCards: (state) => state.user.activeLinkedPaymentCards,
    hasValidEmail: (state) => state.user.hasValidEmail,
    getActiveLinkedPaymentCardsCount: (state) =>
      state.user.activeLinkedPaymentCardsCount,
    hasEverLinkedAPaymentCard: (state) => state.user.everLinkedPaymentCard,
    hasReachedMaxCards: (state) => state.user.maxCardsReached,
    getMaxCardsCount: (state) => state.user.maxCardsCount,
    isUnauthorizedError: (state) => state.unauthorizedError,
    isAuthenticationInProgress: (state) => state.authenticationInProgress,
    getUserExternalId: (state) => state.user.externalId,
  },
});
