import { START_LOCATION, GuardAbstraction } from 'vue-guarded-router';
import { useAuthStore } from '@/stores/AuthStore';

export default class SetBearerTokenGuard extends GuardAbstraction {
  isApplied(to, from) {
    return useAuthStore().isLoggedIn && from === START_LOCATION;
  }

  handle() {
    useAuthStore().setTokenGlobalHeaders();
  }
}
