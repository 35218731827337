<script setup>
  import { inject, onMounted, resolveComponent } from 'vue';
  import { useConsentStore } from '@/stores/ConsentStore';

  const consentStore = useConsentStore();
  const modalManager = inject('modalManager');
  const consentModal = resolveComponent('TheModalConsent');

  defineProps({
    center: {
      type: Boolean,
      default: false,
    },
  });

  onMounted(async () => {
    if (!consentStore.hasConsent) {
      await modalManager.openUniqueModal(consentModal);
    }
  });
</script>

<template>
  <link-skip-main-content />

  <div :class="{ 'center-content': center }">
    <the-header />
    <the-mobile-navigation />
    <main tabindex="-1" id="main" class="main-content">
      <div class="content-wrapper">
        <slot />
      </div>
    </main>
    <the-footer />
  </div>
</template>

<style lang="scss" scoped>
  .main-content {
    @include main-content-height(54px, 456px);
    display: flex;
    justify-content: center;

    @include md-breakpoint {
      @include main-content-height(54px, 278px);
    }

    @include lg-breakpoint {
      @include main-content-height(107px, 228px);
    }

    &:focus {
      outline: none;
    }

    .content-wrapper {
      @include content-area-width;
      display: flex;
      flex-direction: column;

      padding-inline: 16px;
      padding-block: 24px 80px;

      @include md-breakpoint {
        padding-inline: 32px;
      }

      @include lg-breakpoint {
        padding-block-end: 160px;
        padding-inline: 36px;
      }
    }
  }

  .center-content {
    .content-wrapper {
      padding-block: 40px 80px;

      @include lg-breakpoint() {
        justify-content: center;
        padding-block-start: 80px;
      }
    }
  }
</style>
