<script setup>
  import { useUserStore } from '@/stores/UserStore';
  import emitter from '@/composables/event-bus';
  import { getExceptions } from 'api';
  import { inject, onMounted, onUnmounted, ref, watchEffect } from 'vue';
  const { MessageExceptionError, ValidationExceptionErrors } = getExceptions();

  const loaderHandler = inject('loaderHandler');

  const props = defineProps({
    card: Object,
  });

  const userStore = useUserStore();

  const isLoading = ref(false);

  async function handleRemoveCard(closeModal) {
    try {
      await userStore.removeCard(props.card.public_id);

      emitter.$emit('alert::success-alert::open', {
        message: 'remove_card_action_success_text',
      });

      closeModal();
    } catch (e) {
      if (
        e instanceof MessageExceptionError ||
        e instanceof ValidationExceptionErrors
      ) {
        emitter.$emit('alert::error-alert::open', {
          message: e.messages ?? e.message,
        });

        closeModal();
      }
    }
  }

  watchEffect(() => {
    if (loaderHandler.isLoading('userStore::loader::removeCard')) {
      isLoading.value = true;
    }
  });

  onUnmounted(() => {
    isLoading.value = false;
  });

  onMounted(() => {
    emitter.$emit('alert::reset-alert::close');
  });
</script>

<template>
  <base-modal
    :title="$translation('the_modal_remove_card_action_title', 'Unlink card')"
    :text="
      $translation(
        'the_modal_remove_card_action_text',
        'Are you sure you want to unlink the following credit card? This action cannot be undone.',
      )
    "
    id="remove-card-modal"
  >
    <card-linked class="unlink-modal-card" :card="card" />

    <template v-slot:footer="{ close }">
      <button-remove
        :loading="isLoading"
        @click-state="handleRemoveCard(close)"
        loading-message="aria_label_loading_modal_remove_card_action"
      >
        {{
          $translation(
            'the_modal_remove_card_action_confirm_button',
            'Unlink Card',
          )
        }}
      </button-remove>

      <button-secondary :aria-disabled="isLoading" @click-state="close">
        {{
          $translation('the_modal_remove_card_action_cancel_button', 'Cancel')
        }}
      </button-secondary>
    </template>
  </base-modal>
</template>

<style lang="scss" scoped>
  .unlink-modal-card {
    margin-block-start: 24px;

    @include md-breakpoint {
      max-width: 412px;
    }
  }
</style>
