<script setup></script>

<template>
  <div>
    <div class="icon">
      <div class="spinner"></div>
      <div class="spinner"></div>
      <div class="spinner"></div>
      <div class="spinner"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .icon {
    display: block;
    height: 60px;
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: rotate(-20deg) translateX(-30%) translateY(-65%);
    pointer-events: none;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease-in-out 0.3s;

    @include md-breakpoint {
      width: 100px;
      height: 100px;
    }

    .spinner {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 60px;
      height: 60px;
      border: 3.5px solid;
      border-radius: 50%;
      animation: loader 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;

      @include md-breakpoint {
        width: 100px;
        height: 100px;
      }

      &:nth-child(1) {
        animation-delay: -0.375s;
      }

      &:nth-child(2) {
        animation-delay: -0.25s;
      }

      &:nth-child(3) {
        animation-delay: -0.125s;
      }
    }
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
