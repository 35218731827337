import { defineStore } from 'pinia';

const CONSENT_TYPE_FULL = 'full';
const CONSENT_TYPE_NECESSARY = 'necessary';

export const useConsentStore = defineStore('consent', {
  state: () => ({
    consentChoice: null,
  }),

  actions: {
    setConsentChoice(giveFullConsent) {
      this.consentChoice = giveFullConsent
        ? CONSENT_TYPE_FULL
        : CONSENT_TYPE_NECESSARY;
    },
  },
  getters: {
    hasConsent: (state) => Boolean(state.consentChoice),
    isFullConsent: (state) => state.consentChoice === CONSENT_TYPE_FULL,
    isNecessaryConsent: (state) =>
      state.consentChoice === CONSENT_TYPE_NECESSARY,
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: ['consentChoice'],
      },
    ],
  },
});
