<script setup>
  import { useAuthStore } from '@/stores/AuthStore';
  import { computed } from 'vue';
  import { HEADER_URLS } from '@/configs/url-config.js';
  import { useUtility } from '@/composables/use-utility.js';
  const { getLocaleBasedUrl } = useUtility();

  const headerUrls = HEADER_URLS;

  const oneWorldLink = computed(() =>
    getLocaleBasedUrl(headerUrls.one_world_link),
  );

  const authStore = useAuthStore();
</script>

<template>
  <header id="main-header" class="header-nav">
    <div class="navigation top-navigation">
      <div class="content-wrapper">
        <div class="mobile-content-wrapper">
          <link-internal-router
            aria-current="false"
            class="link-icon-logo"
            :to="{ name: 'ManageCards' }"
          >
            <span class="visually-hidden">
              {{ $translation('aria_label_logo_cathay', 'Cathay') }}
            </span>
            <logo-cathay-short logo-id="mobile-header-logo" />
          </link-internal-router>

          <button-link
            data-bs-toggle="offcanvas"
            data-bs-target="#mobile-navigation"
            aria-controls="mobile-navigation"
            class="button-icon-profile"
            modal-button="the-mobile-navigation-button"
          >
            <icon-profile class="icon" />
            <span class="visually-hidden">
              {{ $translation('aria_label_icon_profile', 'Profile') }}
            </span>
          </button-link>
        </div>

        <link-help-and-support
          class="help-and-support-link"
          v-slot="slotProps"
          :isAccessibilityIconVisible="false"
        >
          <icon-question class="icon-question" />
          {{ slotProps.label }}
          <icon-another-page class="icon-another-page" />
        </link-help-and-support>
      </div>
    </div>

    <div class="navigation bottom-navigation">
      <div class="content-wrapper">
        <link-internal-router
          aria-current="false"
          :to="{ name: 'ManageCards' }"
        >
          <span class="visually-hidden">
            {{ $translation('aria_label_logo_cathay', 'Cathay') }}
          </span>
          <logo-cathay class="logo-cathay" />
        </link-internal-router>

        <div class="right-info">
          <the-profile-dropdown
            v-if="authStore.isLoggedIn && authStore.getUserExternalId"
          />
          <link-external
            class="link-logo-one-world"
            :href="oneWorldLink"
            :isAccessibilityIconVisible="false"
          >
            <logo-one-world />
          </link-external>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
  .header-nav {
    @include lg-breakpoint {
      height: 107px;
    }

    .navigation {
      display: flex;
      justify-content: center;

      .content-wrapper {
        @include content-area-width;
      }

      &.top-navigation {
        background-color: $septenary-color;
        height: 54px;
        @include lg-breakpoint {
          height: 32px;
        }

        .content-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 15px 8px;

          @include lg-breakpoint {
            padding: 6px 48px;
            justify-content: flex-end;
          }

          .mobile-content-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;

            @include lg-breakpoint {
              display: none;
            }

            .link-icon-logo {
              width: 20px;
              height: 24px;
              cursor: pointer;
              margin-inline-start: 50%;
              transform: translate(-50%);
            }

            .button-icon-profile {
              cursor: pointer;
              padding-inline-start: 5px;
            }
          }

          .help-and-support-link {
            display: none;
            align-items: center;
            min-width: max-content;
            font-size: 0.875em;
            line-height: 20px;
            font-family: $font-regular-heading;
            height: 23px;

            @include lg-breakpoint {
              display: flex;
            }

            .icon-question {
              width: 14px;
              height: 14px;
            }

            .icon-another-page {
              width: 14px;
              height: 20px;
            }
          }
        }
      }

      &.bottom-navigation {
        display: none;

        @include lg-breakpoint {
          display: flex;
        }

        .content-wrapper {
          background: $quaternary-color;
          width: 100%;
          padding-block: 21px 14px;
          padding-inline: 36px;

          @include lg-breakpoint {
            display: flex;
            justify-content: space-between;
          }

          .logo-cathay {
            width: 124px;
            height: 30px;
          }

          .right-info {
            display: flex;
            align-items: center;

            .link-logo-one-world {
              margin-inline-start: 16px;
            }
          }
        }
      }
    }
  }
</style>
