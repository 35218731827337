import { GuardAbstraction } from 'vue-guarded-router';
import { useAuthStore } from '@/stores/AuthStore';

export default class ResetUnauthorizedErrorFlag extends GuardAbstraction {
  isApplied() {
    return useAuthStore().isUnauthorizedError;
  }

  handle() {
    useAuthStore().resetUnauthorizedError();
  }
}
