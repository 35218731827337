import { markRaw, reactive } from 'vue';

export function useModalRegistry() {
  const registeredModals = reactive({});

  /**
   * Registers a modal component with a unique ID for later use.
   * Make modal component raw, to avoid reactive component overhead.
   */
  function registerModal(id, component) {
    if (registeredModals[id]) {
      return;
    }

    registeredModals[id] = markRaw(component);
  }

  /**
   * Get registered modal by its ID.
   */
  function getRegisteredModal(id) {
    const modal = registeredModals[id];

    if (!modal) {
      return null;
    }

    return modal;
  }

  /**
   * Unregister registered modal by its ID.
   */
  function unregisterModal(id) {
    if (!registeredModals[id]) {
      return;
    }

    delete registeredModals[id];
  }

  return {
    registerModal,
    getRegisteredModal,
    unregisterModal,
    registeredModals,
  };
}
