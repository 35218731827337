import { readonly, ref } from 'vue';

export function useFocusManager() {
  const focusedElementRef = ref(null);

  function setFocus(element) {
    if (element && element.focus) {
      element.focus();
      focusedElementRef.value = element;
    }
  }

  function restoreFocus() {
    if (focusedElementRef.value && focusedElementRef.value.focus) {
      focusedElementRef.value.focus();
    } else {
      resetFocusElement();
    }
  }

  function resetFocusElement() {
    focusedElementRef.value = null;
  }

  return {
    setFocus,
    restoreFocus,
    resetFocusElement,
    focusedElementRef: readonly(focusedElementRef),
  };
}
