<script setup></script>

<template>
  <button
    type="button"
    class="btn-close base-close"
    :aria-label="$translation('aria_label_button_close', 'Close')"
  >
    <icon-close class="icon-close" />
  </button>
</template>

<style lang="scss" scoped>
  .base-close {
    height: 24px;
    width: 24px;
    margin: 0;
    background: none;
    font-size: 1em;

    .icon-close {
      height: 24px;
      width: 24px;
    }
  }
</style>
