<script setup>
  import { computed, onMounted, ref, watch } from 'vue';
  import { Dropdown } from 'bootstrap';
  import { useWindowSize } from '@vueuse/core';

  const { width } = useWindowSize();

  const toggleButton = ref(false);
  const profileDropdown = ref(null);
  let dropdownInstance = null;

  const flipIcon = computed(() => {
    return toggleButton.value ? 'flip-icon' : '';
  });

  onMounted(() => {
    profileDropdown.value
      ? (dropdownInstance = new Dropdown(profileDropdown.value))
      : (dropdownInstance = null);

    profileDropdown.value.addEventListener('shown.bs.dropdown', () => {
      toggleButton.value = true;
    });

    profileDropdown.value.addEventListener('hidden.bs.dropdown', () => {
      toggleButton.value = false;
    });
  });

  watch(width, (newVal) => {
    if (dropdownInstance && newVal < 992) {
      dropdownInstance.hide();
    }
  });
</script>

<template>
  <div class="dropdown profile-dropdown" ref="profileDropdown">
    <button
      class="profile-dropdown-button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      data-bs-auto-close="outside"
      aria-controls="profile-dropdown-menu"
    >
      <content-member-info icon-id="profile-dropdown-button" v-slot="slotProps">
        {{ $translation('profile_button_partial_label', 'Welcome,') }}
        <span class="visually-hidden">
          <!-- Whitespace necessary to ensure proper spacing for screen readers -->
          &nbsp;
        </span>
        <span class="member-number">{{ slotProps.number }}</span>
      </content-member-info>
      <icon-down class="arrow-icon" :class="[flipIcon]" />
    </button>

    <ul id="profile-dropdown-menu" class="dropdown-menu profile-dropdown-menu">
      <li class="dropdown-item profile-dropdown-item">
        <p class="profile-member">
          <content-member-info />
        </p>
      </li>

      <li class="dropdown-item profile-dropdown-item">
        <link-account-overview class="profile-dropdown-action" />
      </li>

      <li>
        <hr class="dropdown-divider profile-divider" aria-hidden="true" />
      </li>

      <li class="dropdown-item profile-dropdown-item">
        <button-sign-out class="profile-dropdown-action" />
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  .profile-dropdown {
    .profile-dropdown-button {
      font-family: $font-regular-heading;
      color: $duodecenary-color;
      border: 0;
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      height: max-content;
      padding: 0;
      font-size: 1em;

      &:hover {
        color: $duodecenary-color;
        text-decoration: underline;
      }

      &:focus-visible {
        outline: $primary-color auto 1px;
        outline-offset: 2px;
      }
      :deep(.card-tier-icon) {
        margin-inline-end: 8px;
      }

      .member-number {
        font-family: $font-bold-heading;
        margin-inline: 4px;
      }

      .arrow-icon {
        transition: transform 0.2s ease;
        &.flip-icon {
          transform: scaleY(-1);
        }
      }
    }

    .profile-dropdown-menu {
      border-radius: 0;
      padding: 40px;
      width: 476px;
      border-color: $duonary-color;
      margin-block-start: 12px !important;

      .profile-dropdown-item {
        padding: 0;

        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
        }

        &:first-child {
          margin-block-end: 16px;
        }

        .profile-member {
          margin: 0;
          color: $duodecenary-color;
          display: flex;
          font-family: $font-regular-heading;
        }

        .profile-dropdown-action {
          font-family: $font-regular-heading;
        }
      }

      .profile-divider {
        margin-block: 24px;
        border-color: $duonary-color;
      }
    }
  }
</style>
