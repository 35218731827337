export default {
  install: (app) => {
    app.directive('rotate-180-ltr', (el) => {
      el.classList.add('rotate-180-ltr');
    });
    app.directive('rotate-180-rtl', (el) => {
      el.classList.add('rotate-180-rtl');
    });
  },
};
