// Base class for creating navigation guards.
export default class GuardAbstraction {
  constructor(router) {
    this.router = router;
    // Welcome to the Silence Zone!
    // Enjoy the silence, because this constructor is intentionally left empty.
    // This class serves as a guardian of code, with customizable logic in isApplied, applyGuard, and handle methods.
  }

  /**
   * Sets up the code guard based on custom conditions.
   * If the guard is applied, performs additional checks and calls the original function.
   *
   * @param {...any} args - The arguments required for the guard and the original function.
   */
  async setupGuard(...args) {
    if (this.isApplied(...args)) {
      // Call your original function with the required arguments
      const result = await this.handle(...args);

      // If handle() calls next() or does not return a value, handle() will simply finish.
      // If handle() returns a navigation target, return it from handle().
      if (result !== undefined) {
        return result;
      }
    }
  }

  /**
   * Determines whether the guard should be applied based on custom conditions.
   *
   * @param {object} [to] - The 'to' parameter for guard conditions. Optional.
   * @param {object} [from] - The 'from' parameter for guard conditions. Optional.
   * @returns {boolean} - True if the guard should be applied, false otherwise.
   */
  isApplied(to, from) {
    return false;
  }

  /**
   * Handles the guarded logic, which may include the original function execution.
   *
   * @param {object} [to] - The 'to' parameter for the original function. Optional.
   * @param {object} [from] - The 'from' parameter for the original function. Optional.
   * @param {function} [next] - The next function in the middleware chain. Optional.
   */
  async handle(to, from, next) {
    // You can choose not to use the next function here.
    // Depending on your logic, you might want to use it later.
    next();
  }
}
