import { reactive } from 'vue';

export default {
  install: (app, options) => {
    // Check for Vue 3 compatibility.
    const [majorVersion, minorVersion] = app.version.split('.').map(Number);
    if (majorVersion < 3) {
      console.warn('This plugin requires Vue 3');
      return;
    }

    const translationsState = reactive({
      translations: null,
      locale: null,
    });

    function setLanguageLocale(locale) {
      translationsState.locale = locale;
    }

    function setTranslations(translations) {
      translationsState.translations = translations;
    }

    function getTranslation(key, staticText, replacements = {}) {
      // From translations with key.
      let text =
        translationsState.translations?.[translationsState.locale]?.[key] ?? '';

      // From static text.
      if (options.showStaticText && options.showStaticText === true) {
        text = staticText ?? '';
      }

      // Replace keys in the text with the provided replacements.
      for (const [replacementKey, replacementValue] of Object.entries(
        replacements,
      )) {
        text = text.replace(
          new RegExp(`\\[${replacementKey}\\]`, 'g'),
          String(replacementValue),
        );
      }

      return text;
    }

    // Function to check if a translation key exists.
    function hasTranslation(key) {

      const { translations, locale } = translationsState;

      if (!translations?.[locale]) {
        return false;
      }

      return (
        translations?.[locale] !== undefined &&
        translations?.[locale] !== null &&
        translations &&
        locale &&
        Object.prototype.hasOwnProperty.call(translations?.[locale], key)
      );
    }

    // Provide the translation functions to the Vue application.
    const provideTranslations = () => {
      app.provide('translations', {
        setLanguageLocale,
        setTranslations,
        getOne: getTranslation,
        has: hasTranslation,
      });
    };

    if (majorVersion === 3 && minorVersion < 3) {
      // Enables utilization in global guards within vue-router version 4.2.0. or above.
      app.runWithContext(provideTranslations);
    } else {
      provideTranslations();
    }

    app.config.globalProperties.$translation = getTranslation;
  },
};
