class EventBus {
  /**
   * Constructor function that runs when a new instance of EventBus is created.
   * Initializes the 'events' property as an empty object, which will store event handlers.
   */
  constructor() {
    this.events = {};
  }

  /**
   * Register an event handler for a specific event.
   * @param {string} event - The name of the event to listen for.
   * @param {function} callback - The function to execute when the event is emitted.
   */
  $on(event, callback) {
    // If the event is not registered yet, create an array to store callbacks.
    if (!this.events[event]) {
      this.events[event] = [];
    }
    // Add the callback function to the array of event handlers.
    this.events[event].push(callback);
  }

  /**
   * Remove an event handler for a specific event.
   * @param {string} event - The name of the event to remove the handler from.
   * @param {function} callback - The callback function to be removed.
   */
  $off(event, callback) {
    // Check if the event has registered handlers.
    if (this.events[event]) {
      // Use filter to remove the specified callback from the array of handlers.
      this.events[event] = this.events[event].filter((cb) => cb !== callback);
    }
  }

  /**
   * Asynchronously emit an event, triggering all registered event handlers for that event.
   * @param {string} event - The name of the event to emit.
   * @param {*} data - Data to be passed to the event handlers.
   * @throws {Error} - If an error occurs in an event handler, it is logged and re-thrown.
   */
  async $emit(event, data) {
    // Check if there are registered handlers for the event.
    if (this.events[event]) {
      // Iterate through the event handlers and execute them, passing 'data'.
      for (const callback of this.events[event]) {
        try {
          // Execute the callback and wait for it to complete (if it returns a Promise).
          await callback(data);
        } catch (error) {
          // Handle errors in event handlers and log them.
          // Re-throw the error to notify the caller.
          console.error(`Error in event handler for ${event}:`, error);
          throw new Error(`Error in event handler for ${event}: ${error}`);
        }
      }
    }
  }

  /**
   * Register an event handler that will be called only once, then removed.
   * @param {string} event - The name of the event to listen for.
   * @param {function} callback - The function to execute when the event is emitted.
   */
  $once(event, callback) {
    // Create a new callback that removes itself after being called once.
    const onceCallback = (data) => {
      this.$off(event, onceCallback); // Remove the onceCallback after it's been called.
      callback(data);
    };
    // Register the onceCallback to listen for the event.
    this.$on(event, onceCallback);
  }

  /**
   * Register an event handler that will be called before the main event handlers.
   * @param {string} event - The name of the event to listen for.
   * @param {function} callback - The function to execute before other event handlers.
   */
  $before(event, callback) {
    // If the 'before' event type doesn't exist, create an array for it.
    if (!this.events[`before:${event}`]) {
      this.events[`before:${event}`] = [];
    }
    // Add the 'before' callback to the array of before-event handlers.
    this.events[`before:${event}`].push(callback);
  }

  /**
   * Register an event handler that will be called after the main event handlers.
   * @param {string} event - The name of the event to listen for.
   * @param {function} callback - The function to execute after other event handlers.
   */
  $after(event, callback) {
    // If the 'after' event type doesn't exist, create an array for it.
    if (!this.events[`after:${event}`]) {
      this.events[`after:${event}`] = [];
    }
    // Add the 'after' callback to the array of after-event handlers.
    this.events[`after:${event}`].push(callback);
  }
}

export default EventBus;

/**
 * Additional Methods (Optional):
 * These methods can be implemented later on to provide further flexibility and control over event handling.
 */

// $on(event, callback): Adds an event listener for the specified event
// Usage: eventBus.$on('eventName', callback)

// $once(event, callback): Adds an event listener that will only be called once for the specified event
// Usage: eventBus.$once('eventName', callback)

// $off(event, callback): Removes an event listener for the specified event
// Usage: eventBus.$off('eventName', callback)

// $emit(event, eventData): Triggers the specified event and calls all its listeners
// Usage: eventBus.$emit('eventName', eventData)

// $hasListener(event): Checks if there are any listeners for the specified event
// Usage: eventBus.$hasListener('eventName')

// $listeners(event): Returns an array of listeners for the specified event
// Usage: eventBus.$listeners('eventName')

// $offAll(event): Removes all event listeners for the specified event
// Usage: eventBus.$offAll('eventName')

// $offAllEvents(): Removes all event listeners for all events
// Usage: eventBus.$offAllEvents()

// $setMaxListeners(event, maxListeners): Sets the maximum number of listeners for an event
// Usage: eventBus.$setMaxListeners('eventName', maxListeners)

// $getMaxListeners(event): Gets the maximum number of listeners for an event
// Usage: eventBus.$getMaxListeners('eventName')

// $addListener(event, callback): Alias for $on, adds an event listener for the specified event
// Usage: eventBus.$addListener('eventName', callback)

// $removeListener(event, callback): Alias for $off, removes an event listener for the specified event
// Usage: eventBus.$removeListener('eventName', callback)

// $removeAllListeners(event): Removes all event listeners for the specified event
// Usage: eventBus.$removeAllListeners('eventName')
