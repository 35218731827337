import ServiceAbstraction from '../service-abstraction.js';

class VerifyTokenService extends ServiceAbstraction {
  static identifier = 'VerifyTokenService';
  static baseUrlPath = '/verify-token';

  constructor(httpClient, configManager, options = {}) {
    super(httpClient, configManager);
    this.options = options;
  }

  inputParams() {
    return this.options;
  }

  async doCall(options) {
    const { params = {}, config = {} } = options;
    return super._httpLayer().post(params, config);
  }

  output(response) {
    return response;
  }
}

export default VerifyTokenService;
