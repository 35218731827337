// Buttons
import BaseButton from '@/components/ui/buttons/BaseButton.vue';

// Cards
import BaseCard from '@/components/ui/cards/BaseCard.vue';

// Links
import LinkExternal from '@/components/ui/links/LinkExternal.vue';
import LinkInternal from '@/components/ui/links/LinkInternal.vue';
import LinkInternalRouter from '@/components/ui/links/LinkInternalRouter.vue';
import LinkInternalButton from '@/components/ui/links/LinkInternalButton.vue';

// Inputs
import BaseFloatingInput from '@/components/ui/forms/BaseFloatingInput.vue';
import BaseFloatingSelect from '@/components/ui/forms/BaseFloatingSelect.vue';

// Alerts
import BaseAlertPrimary from '@/components/ui/alerts/BaseAlertPrimary.vue';

// Modals
import BaseModal from '@/components/ui/modals/BaseModal.vue';

export default {
  install: (app) => {
    // Buttons
    app.component('BaseButton', BaseButton);

    // Links
    app.component('LinkExternal', LinkExternal);
    app.component('LinkInternal', LinkInternal);
    app.component('LinkInternalRouter', LinkInternalRouter);
    app.component('LinkInternalButton', LinkInternalButton);

    // Cards
    app.component('BaseCard', BaseCard);

    // Inputs
    app.component('BaseFloatingInput', BaseFloatingInput);
    app.component('BaseFloatingSelect', BaseFloatingSelect);

    // Alerts
    app.component('BaseAlertPrimary', BaseAlertPrimary);

    // Modal
    app.component('BaseModal', BaseModal);
  },
};
