<script setup></script>

<template>
  <div class="base-alert">
    <div class="icon-wrapper">
      <slot name="icon"></slot>
    </div>

    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .base-alert {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 4px;
    padding-inline: 16px;
    padding-block: 12px;
    margin-block-end: 24px;

    .icon-wrapper {
      width: 24px;
      height: 24px;
    }
    :slotted(.alert-label) {
      max-width: 976px;
      margin: 0;
      font-size: 0.875em;
      line-height: 20px;
      font-family: $font-regular-heading;
      margin-block-start: 4px;
    }
  }
</style>
