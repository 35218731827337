<script setup>
  import { inject, ref, watchEffect } from 'vue';

  // Accessing the modal manager for global modal control.
  const modalManager = inject('modalManager');

  // References for the active modal element and its state.
  const activeElementRef = ref(null);

  // Watching for changes in the active modal to update the element reference.
  watchEffect(() => {
    // Check if there is an active modal and an element reference.
    if (
      modalManager.state.activeModal !== null &&
      activeElementRef.value?.$el
    ) {
      // Update the modal manager with the new active modal element reference.
      modalManager._setActiveElement(activeElementRef.value?.$el);
    }
  });
</script>

<template>
  <!-- Dynamically render modal. -->
  <component
    v-if="modalManager.state.activeModal !== null"
    :key="modalManager.state.activeModal.id"
    :is="modalManager.state.activeModal.component"
    v-bind="modalManager.state.activeModal.props"
    v-on="modalManager.state.activeModal.eventHandlers"
    ref="activeElementRef"
  />
</template>
