<script setup></script>

<template>
  <base-alert-primary class="alert-success">
    <template v-slot:icon>
      <icon-checkmark-fill class="alert-icon" />
    </template>
    <slot />
  </base-alert-primary>
</template>

<style lang="scss" scoped>
  .alert-success {
    background-color: $quinary-color;
  }

  .alert-icon {
    color: $secondary-color;
  }
</style>
