import { GuardAbstraction } from 'vue-guarded-router';
import { useAuthStore } from '@/stores/AuthStore';

export default class HasValidEmailGuard extends GuardAbstraction {
  isApplied() {
    return useAuthStore().isLoggedIn && !useAuthStore().hasValidEmail;
  }

  handle(to, from, next) {
    next({
      name: 'ManageCards',
      params: { locale: to.params.locale },
    });
  }
}
