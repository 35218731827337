import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/AuthStore';
import { useApi, getServices } from 'api';
import { useCardinalStore } from '@/stores/CardinalStore';

export const useUserStore = defineStore('user', {
  state: () => ({
    cards: null,
    cardNumber: null,
    cardPublicId: null,
  }),

  actions: {
    async fetchCards() {
      const loaderKey = 'userStore::loader::cards';

      if (!this.isAuth || this.isLoading(loaderKey)) {
        return;
      }

      this.startLoader(loaderKey);

      const api = useApi();
      const { GetCardsService } = getServices();

      let res = null;
      await api.call(GetCardsService).then((response) => {
        res = response;
      });

      this.cards = res.payload;

      // If the profile cards count and userStore cards count becomes inconsistent, retrieve the profile.
      if (
        this.getCardsCount !== useAuthStore().getActiveLinkedPaymentCardsCount
      ) {
        await useAuthStore().fetchProfile();
      }

      this.stopLoader(loaderKey);
    },

    async addCard(params) {
      const loaderKey = 'userStore::loader::addCard';

      if (this.isLoading(loaderKey)) {
        return;
      }

      this.startLoader(loaderKey);

      const api = useApi();
      const { AddCardService } = getServices();

      this.cardNumber = params.cardNumber;

      const options = {
        params: {
          cardNumber: params.cardNumber,
          month: params.expiryMonth,
          year: params.expiryYear,
          consent: params.consent,
          'g-recaptcha-response': params.captchaToken,
          termsAndConsConsent: params.termsAndConsConsent,
          privacyPolicyConsent: params.privacyPolicyConsent,
          issuer_country: params.issuerCountryId,
          browserLanguage: navigator.language || navigator.userLanguage, // Gets the browser's language.
          browserColorDepth: screen.colorDepth, // Gets the color depth of the screen.
          browserScreenHeight: screen.height, // Gets the screen's height.
          browserScreenWidth: screen.width, // Gets the screen's width.
          browserTimeZone: -new Date().getTimezoneOffset(), // Gets the browser's time zone offset from UTC in minutes.
        },
      };

      let res = null;
      await api.call(AddCardService, options).then((response) => {
        res = response;
      });

      useCardinalStore().stepUpToken = res.payload.token;
      this.cardPublicId = res.payload.cardPublicId;

      return res.payload.isAuthenticationSupport;
    },

    async removeCard(publicId) {
      const loaderKey = 'userStore::loader::removeCard';

      if (!this.isAuth || this.isLoading(loaderKey)) {
        return;
      }

      this.startLoader(loaderKey);

      const api = useApi();
      const { DeleteCardService } = getServices();

      await api.call(DeleteCardService, { params: publicId });

      await this.fetchCards();

      await useAuthStore().fetchProfile();

      this.stopLoader(loaderKey);
    },

    startLoader(key) {
      this.$loaderHandler.start(key);
    },

    stopLoader(key) {
      this.$loaderHandler.stop(key);
    },

    isLoading(key) {
      return this.$loaderHandler.isLoading(key);
    },
  },

  getters: {
    isAuth: () => useAuthStore().isLoggedIn,
    getCards: (state) => state.cards,
    getCardsCount: (state) => (state.cards ? state.cards.length : 0),
  },
});
