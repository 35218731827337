<script setup>
  import { computed, inject } from 'vue';

  const props = defineProps({
    disabled: {
      type: Boolean,
      default: false,
    },
    ariaDisabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: 'aria_label_icon_loading_spinner',
    },
  });

  const emit = defineEmits(['clickState']);

  const translations = inject('translations');

  const isDisabled = computed(() => props.disabled);
  const isAriaDisabled = computed(() => props.ariaDisabled || props.disabled);
  const isLoading = computed(() => (props.loading ? 'loading' : ''));

  const isAriaDisabledBtn = computed(() =>
    props.ariaDisabled ? 'aria-disabled-btn' : '',
  );

  const loadingText = computed(() =>
    props.loading ? translations.getOne(props.loadingMessage, 'Loading') : '',
  );

  function clickState() {
    if (isAriaDisabled.value || isLoading.value) {
      return;
    }

    emit('clickState');
  }
</script>

<template>
  <button
    :disabled="isDisabled"
    :aria-disabled="isAriaDisabled"
    ref="baseButton"
    type="button"
    class="base-button"
    :class="[isLoading, isAriaDisabledBtn]"
    @click="clickState"
  >
    <text-assistive :value="loadingText" aria-live="polite" v-if="isLoading" />
    <icon-spinner class="spinner-icon" v-if="isLoading" />
    <slot v-else />
  </button>
</template>

<style lang="scss" scoped>
  .base-button {
    font-size: 1em;
    line-height: 24px;
    text-decoration: none;
    display: flex;
    gap: 4px;
    border: none;
    border-radius: 0;
    padding: 18px 24px;
    font-family: $font-regular-text;
    position: relative;

    height: 60px;
    width: 100%;
    cursor: pointer;

    &.loading {
      cursor: progress;

      .spinner-icon {
        :deep(.icon) {
          height: 32px;
          width: 32px;

          .spinner {
            height: 32px;
            width: 32px;
            border-width: 2px;
          }
        }
      }
    }

    @include md-breakpoint {
      min-width: 216px;
      width: max-content;
    }

    &:focus-visible {
      outline: 2px solid -webkit-focus-ring-color;
    }

    &:disabled {
      color: $button-disabled-color;
      background: $button-disabled-background-color;
      border-color: $button-disabled-background-color;
      cursor: not-allowed;
      opacity: 1;

      &:hover {
        text-decoration: none;
        color: $button-disabled-color;
        background: $button-disabled-background-color;
        border-color: $button-disabled-background-color;
      }
    }

    &.aria-disabled-btn {
      color: $button-disabled-color;
      background: $button-disabled-background-color;
      border-color: $button-disabled-background-color;
      cursor: not-allowed;
      opacity: 1;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none !important;
        color: $button-disabled-color !important;
        background: $button-disabled-background-color !important;
        border-color: $button-disabled-background-color !important;
      }
    }
  }
</style>
