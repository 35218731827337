<script setup></script>

<template>
  <base-alert-primary class="alert-error">
    <template v-slot:icon>
      <icon-error class="alert-icon" />
    </template>
    <slot />
  </base-alert-primary>
</template>

<style lang="scss" scoped>
  .alert-error {
    background-color: $nonary-color;
  }

  .alert-icon {
    color: $tertiary-color;
  }
</style>
