<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    target: {
      type: String,
      default: '_blank',
    },
    rel: String,
    isAccessibilityEnabled: {
      type: Boolean,
      default: true,
    },
    isAccessibilityIconVisible: {
      type: Boolean,
      default: true,
    },
  });

  const isNewWindow = computed(() => props.target === '_blank' && !props.rel);
  const elmRel = computed(() =>
    props.rel ? props.rel : 'noopener noreferrer',
  );

  // Handle keyboard events for the RouterLink component.
  const handleKeyDown = (event) => {
    if (event.code === 'Space') {
      event.preventDefault(); // Prevent scrolling when using space button.
      event.target.click(); // Trigger the link's click event.
    }
  };
</script>

<template>
  <a class="base-link" :rel="elmRel" :target="target" @keydown="handleKeyDown">
    <slot />

    <template v-if="isNewWindow && isAccessibilityEnabled">
      <icon-another-page
        :class="{ 'visually-hidden': !isAccessibilityIconVisible }"
      />
      <span class="visually-hidden">
        <!-- Whitespace necessary to ensure proper spacing for screen readers -->
        &nbsp;
        {{ $translation('aria_label_icon_another_page', 'opens new tab') }}
      </span>
    </template>

    <template v-if="!isNewWindow && isAccessibilityEnabled">
      <span class="visually-hidden">
        <!-- Whitespace necessary to ensure proper spacing for screen readers -->
        &nbsp;
        {{
          $translation('aria_label_link_same_window', 'opens in same window')
        }}
      </span>
    </template>
  </a>
</template>

<style lang="scss" scoped>
  .base-link {
    color: $link-color;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 4px;
    height: max-content;
    width: max-content;
    font-family: $font-regular-text;

    font-size: 1em;
    line-height: 24px;
    text-align: left;

    &:hover,
    &:focus-visible {
      color: $link-color;
      text-decoration: underline;
    }

    &:focus-visible {
      outline: $primary-color auto 1px;
      outline-offset: 2px;
    }

    &:visited {
      color: $link-color;
    }
  }
</style>
