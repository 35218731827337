<script setup></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    fill="currentColor"
    aria-hidden="true"
  >
    <title>
      {{ $translation('aria_label_icon_error', 'Error') }}
    </title>
    <g stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
      <rect
        fill-opacity="0"
        fill="#FFFFFF"
        x="0"
        y="0"
        width="24"
        height="24"
      />
      <path
        d="M12.6657081,18.205595 C12.5090681,18.365835 12.2871681,18.4460751 12.000008,18.4460751 C11.7128279,18.4460751 11.4909279,18.365835 11.3342879,18.205595 C11.1776678,18.045355 11.0993478,17.8460149 11.0993478,17.6076949 C11.0993478,17.3607548 11.1798078,17.1559348 11.3408279,16.9934548 C11.5017479,16.8309547 11.7215079,16.7496947 12.000008,16.7496947 C12.2784081,16.7496947 12.4981681,16.8309547 12.6591881,16.9934548 C12.8201082,17.1559348 12.9006682,17.3607548 12.9006682,17.6076949 C12.9006682,17.8460149 12.8223482,18.045355 12.6657081,18.205595 M11.3293479,8.85503313 L12.6313281,8.85503313 C12.7325081,8.85503313 12.8138682,8.93831315 12.8115082,9.03943317 L12.6632881,15.3862544 C12.6610081,15.4840945 12.5810081,15.5622345 12.4831081,15.5622345 L11.4713879,15.5622345 C11.3734079,15.5622345 11.2933879,15.4839945 11.2912079,15.3860744 L11.1491478,9.03925317 C11.1468878,8.93819315 11.2282278,8.85503313 11.3293479,8.85503313 M21.78049,18.9052152 L13.0827282,3.84491213 C12.8567482,3.45361205 12.4519881,3.219992 12.000008,3.219992 C11.5480079,3.219992 11.1432478,3.45361205 10.9172678,3.84491213 L2.21950603,18.9051952 C1.99350599,19.2965152 1.99348599,19.7637553 2.21948603,20.1550554 C2.44548608,20.5463755 2.85024616,20.7799955 3.30224625,20.7799955 L20.6977497,20.7799955 C21.1497698,20.7799955 21.5545299,20.5463755 21.78051,20.1550554 C22.00651,19.7637353 22.00649,19.2964952 21.78049,18.9052152"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped></style>
