import ServiceProvider from './providers/service-provider.js';
import MocksServiceProvider from './providers/mocks-service-provider.js';

class DependencyContainer {
  constructor() {
    // Constructor for DependencyContainer class.
    // Initializes instance of ServiceProvider, MocksServiceProvider, and HttpLayerAxios.
    this.serviceProvider = new ServiceProvider();
    this.mocksServiceProvider = new MocksServiceProvider();
    this.httpLayer = null;
  }

  /**
   * Retrieves the appropriate service provider based on whether mocking is enabled.
   * @param {boolean} isMocked - Indicates whether mocking is enabled.
   * @returns {Object} - An instance of the appropriate service provider.
   */
  getServices(isMocked = false) {
    // If mocking is enabled, return services from MocksServiceProvider; otherwise, return services from ServiceProvider.
    return isMocked
      ? this.mocksServiceProvider.getServices()
      : this.serviceProvider.getServices();
  }

  /**
   * Getter for the HTTP layer instance.
   * @returns {InstanceType} httpLayer - The instance of the HTTP layer.
   */
  get getHttpLayer() {
    return this.httpLayer;
  }

  /**
   * Setter for MocksServiceProvider.
   * @param {MocksServiceProvider} mocksServiceProvider - The MocksServiceProvider instance to set.
   */
  set setMocksServiceProvider(mocksServiceProvider) {
    this.mocksServiceProvider = mocksServiceProvider;
  }

  /**
   * Setter for ServiceProvider.
   * @param {ServiceProvider} serviceProvider - The ServiceProvider instance to set.
   */
  set setServiceProvider(serviceProvider) {
    this.serviceProvider = serviceProvider;
  }

  /**
   * Setter for HttpLayer.
   * @param {InstanceType} httpLayer - The HttpLayer instance to set.
   */
  set setHttpLayer(httpLayer) {
    this.httpLayer = httpLayer;
  }
}

export default DependencyContainer;
