<script setup>
  import { computed, inject, reactive, readonly, ref, watchEffect } from 'vue';
  import moment from 'moment';

  const props = defineProps({
    cardMsg: {
      type: String,
      default: null,
    },
    validState: {
      type: Boolean,
      default: false,
    },
  });

  const translations = inject('translations');

  const emit = defineEmits(['update:validState']);

  const cardDate = ref('');

  const inputErrorMsg = ref(null);
  const isCardDateValidState = ref(false);

  const cardDateInputMaskDefault = readonly({
    mask: '##/##',
    maxLength: 5,
  });

  const cardDateMaskRules = reactive({ ...cardDateInputMaskDefault });

  const message = computed(() =>
    props.cardMsg ? props.cardMsg : inputErrorMsg.value,
  );

  const stateMsg = computed(() =>
    message.value
      ? translations.getOne(message.value, 'Please enter a valid expiry date')
      : null,
  );

  const hasInputError = computed(
    () => !!props.cardMsg || !!inputErrorMsg.value,
  );

  const inputStateSuccess = computed(
    () =>
      (props.validState || isCardDateValidState.value) && !hasInputError.value,
  );

  const inputStateError = computed(
    () =>
      (!props.validState || !isCardDateValidState.value) && hasInputError.value,
  );

  watchEffect(() => {
    if (cardDate.value === '') {
      isCardDateValidState.value = false;
      cardDate.value = null;
    }

    emit('update:validState', isCardDateValidState.value);
  });

  // Methods
  async function updateCardDateOnInput() {
    const cardDateClean = validateDate(cardDate.value);
    // Resets.
    isCardDateValidState.value = false;
    inputErrorMsg.value = null;

    Object.assign(cardDateMaskRules, cardDateInputMaskDefault);

    // Check if the length of the input is equal to the desired length
    if (
      cardDate.value &&
      cardDate.value.length === cardDateMaskRules.maxLength
    ) {
      if (cardDateClean.length === 0) {
        return;
      }

      if (!cardDateClean) {
        inputErrorMsg.value = 'link_card_date_error_invalid';
        return;
      }

      // Success: Card number and scheme are valid...
      isCardDateValidState.value = true;
    }
  }

  function validateDate(date) {
    // Check if the date is in the format "MM/YY" using Moment.js
    if (!moment(date, 'MM/YY', true).isValid()) {
      return false;
    }

    // Get the current date
    const currentDate = moment();

    // Remove the day and time components from the current date
    currentDate.startOf('month');

    // Parse the input date
    const inputDate = moment(date, 'MM/YY', true);

    // Check if the input date is an invalid date
    if (!inputDate.isValid()) {
      return false;
    }

    // Remove the day and time components from the input date
    inputDate.startOf('month');

    // Compare the input date with the current date
    if (inputDate.isBefore(currentDate)) {
      // Input date is before the current month
      return false;
    }

    // All validation checks passed, return true
    return true;
  }
</script>

<template>
  <base-floating-input
    v-model="cardDate"
    id="card-date"
    inputmode="numeric"
    autocomplete="cc-exp"
    :label="$translation('input_card_date_label', 'Valid through (Month/Year)')"
    :state-msg="stateMsg"
    :input-state-error="inputStateError"
    :input-state-success="inputStateSuccess"
    :data-maska="cardDateMaskRules.mask"
    :max-length="`${cardDateMaskRules.maxLength}`"
    @input="updateCardDateOnInput"
    class="input-card-date"
    required
  >
  </base-floating-input>
</template>

<style lang="scss" scoped>
  .base-floating-input {
    @include md-breakpoint {
      max-width: 270px;
    }
  }
  html[dir='rtl'] {
    .input-card-date {
      :deep(.base-input) {
        direction: ltr;
        text-align: right;
      }
    }
  }
</style>
