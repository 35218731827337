<script setup></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    aria-hidden="true"
  >
    <title>
      {{ $translation('aria_label_icon_payment_card', 'Payment Card') }}
    </title>
    <g
      id="1_iconography/icon3.0/outlined/lifestyle/payment"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <rect
        id="Rectangle"
        fill-opacity="0"
        fill="#FFFFFF"
        x="0"
        y="0"
        width="24"
        height="24"
      />
      <path
        d="M20.05,4 C21.12696,4 22,4.87304 22,5.95 L22,14.8161 C22,14.92656 21.91046,15.0161 21.8,15.0161 L21.7,15.0161 C21.03726,15.0161 20.5,14.47884 20.5,13.8161 L20.5,9.51482 L3.5,9.51482 L3.5,18.05 C3.5,18.29852 3.70148,18.5 3.95,18.5 L20.05,18.5 C20.29852,18.5 20.5,18.29852 20.5,18.05 L20.5,16.2161 C20.5,16.10564 20.58954,16.0161 20.7,16.0161 L21.8,16.0161 C21.91046,16.0161 22,16.10564 22,16.2161 L22,18.05 C22,19.12696 21.12696,20 20.05,20 L3.95,20 C2.87304,20 2,19.12696 2,18.05 L2,5.95 C2,4.87304 2.87304,4 3.95,4 L20.05,4 Z M18.9947,15.00946 L18.9947,17.00586 L16.01228,17.00586 L16.01228,15.00946 L18.9947,15.00946 Z M11.99274,11.50776 L11.99274,13.00776 L4.99378,13.00776 L4.99378,11.50776 L11.99274,11.50776 Z M20.05,5.5 L3.95,5.5 C3.70148,5.5 3.5,5.70148 3.5,5.95 L3.5,8.01482 L20.5,8.01482 L20.5,5.95 C20.5,5.70148 20.29852,5.5 20.05,5.5 Z"
        id="Shape"
        fill="#2D2D2D"
      />
    </g>
  </svg>
</template>

<style scoped lang="scss">
  // This comment is essential to enable the addition of v-directives to add classes to the class list.
</style>
