<script setup>
  import { computed, onMounted, ref } from 'vue';
  import emitter from '@/composables/event-bus';

  const message = ref(null);
  const messages = ref(null);
  const alertState = ref(null);

  const isSuccess = computed(() => alertState.value === 'success');
  const isError = computed(() => alertState.value === 'error');

  onMounted(() => {
    emitter.$on('alert::success-alert::open', (data) => {
      checkTypeAndAssignMessage(data.message);
      alertState.value = 'success';
      window.scrollTo(0, 0);
    });

    emitter.$on('alert::error-alert::open', (data) => {
      checkTypeAndAssignMessage(data.message);
      alertState.value = 'error';
      window.scrollTo(0, 0);
    });

    emitter.$on('alert::reset-alert::close', () => {
      resetValues();
    });
  });

  function checkTypeAndAssignMessage(value) {
    if (typeof value === 'string') {
      message.value = value;
    } else {
      messages.value = value;
    }
  }

  function resetValues() {
    alertState.value = null;
    message.value = null;
    messages.value = null;
  }
</script>

<template>
  <div>
    <alert-primary-success
      class="alert-primary"
      v-if="isSuccess"
      aria-hidden="true"
    >
      <p v-if="message" class="alert-label">
        {{ $translation(message, 'Successful') }}
      </p>

      <ul v-if="messages" class="message-list">
        <li class="alert-label" v-for="(text, index) in messages" :key="index">
          {{ $translation(text, 'Successful') }}
        </li>
      </ul>
    </alert-primary-success>

    <alert-primary-error
      class="alert-primary"
      v-if="isError"
      aria-hidden="true"
    >
      <p v-if="message" class="alert-label">
        {{ $translation(message, 'An error occurred') }}
      </p>

      <ul v-if="messages" class="message-list">
        <li class="alert-label" v-for="(text, index) in messages" :key="index">
          {{ $translation(text, 'An error occurred') }}
        </li>
      </ul>
    </alert-primary-error>

    <div
      role="alert"
      aria-relevant="additions"
      aria-atomic="true"
      aria-live="assertive"
      class="visually-hidden"
    >
      <p>
        {{ $translation(message, 'An alert happened') }}
      </p>

      <ul>
        <li class="alert-label" v-for="(text, index) in messages" :key="index">
          {{ $translation(text, 'An alert happened') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .alert-primary {
    .message-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
</style>
