import { GuardAbstraction } from 'vue-guarded-router';
import { useLocaleStore } from '@/stores/LocaleStore';
import { useMainStore } from '@/stores/MainStore';
import { inject } from 'vue';
import { useAuthStore } from '@/stores/AuthStore';

export default class LocaleGuard extends GuardAbstraction {
  isApplied() {
    // Applied all the time.
    return true;
  }

  async handle(to, from, next) {
    const translations = inject('translations');
    const modalManager = inject('modalManager');

    // Update locale on each route change.
    const localeStore = useLocaleStore();
    localeStore.setLocale(to.params.locale);

    // Set/Update language locale.
    translations.setLanguageLocale(localeStore.getMainLocale);

    if (!useAuthStore().isUnauthorizedError) {
      try {
        await useMainStore().fetchTranslations();

        // Set translations to translations plugin.
        translations.setTranslations(useMainStore().translations);
      } catch (e) {
        // A flag is required to prevent entering a loop in case fetchTranslations encounters a failure.
        useAuthStore().setUnauthorizedError();

        next({
          name: 'AccessDenied',
          params: { locale: localeStore.getLocale },
        });

        await modalManager.openRegisteredHighPriorityModal(
          'modal-general-error',
          {
            props: {
              title: 'Translations error',
              message:
                'Fetching translations failed, please try to reload page',
              button: 'Ok',
            },
            config: { backdrop: true, keyboard: true },
          },
        );
      }
    }
  }
}
