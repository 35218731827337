<script setup>
  import { ref, onMounted, watch } from 'vue';
  import { Offcanvas } from 'bootstrap';
  import { useWindowSize } from '@vueuse/core';
  import { useAuthStore } from '@/stores/AuthStore';

  const authStore = useAuthStore();

  const { width } = useWindowSize();

  const mobileNavOffCanvas = ref(null);
  let offCanvasInstance = null;

  watch(width, (newVal) => {
    if (offCanvasInstance && newVal > 992) {
      offCanvasInstance.hide();
    }
  });

  onMounted(() => {
    if (mobileNavOffCanvas.value) {
      offCanvasInstance = new Offcanvas(mobileNavOffCanvas.value);
    }
  });
</script>

<template>
  <Teleport to="body">
    <nav
      class="offcanvas offcanvas-end mobile-nav"
      ref="mobileNavOffCanvas"
      tabindex="-1"
      id="mobile-navigation"
      aria-expanded="false"
      aria-controls="mobileNavOffCanvas"
      :aria-label="$translation('aria_label_mobile_menu', 'Mobile menu')"
    >
      <div class="bottom-layer"></div>

      <div class="mobile-nav-header">
        <div class="close-btn-wrapper">
          <button-close
            data-bs-dismiss="offcanvas"
            class="mobile-nav-close-btn"
          />
        </div>

        <p class="mobile-nav-member">
          <content-member-info icon-id="mobile-navigation-member-icon" />
        </p>
      </div>

      <div class="top-layer">
        <div class="mobile-nav-body">
          <ul class="mobile-nav-links">
            <li class="mobile-nav-item" v-if="authStore.isLoggedIn">
              <link-account-overview class="mobile-nav-action" />
            </li>

            <li class="mobile-nav-item">
              <link-help-and-support
                class="mobile-nav-action"
                :isAccessibilityIconVisible="false"
              />
            </li>
          </ul>
        </div>

        <div class="mobile-nav-footer">
          <button-sign-out
            v-if="authStore.isLoggedIn"
            data-bs-dismiss="offcanvas"
            class="mobile-nav-action"
          />
        </div>
      </div>
    </nav>
  </Teleport>
</template>

<style lang="scss" scoped>
  .mobile-nav {
    width: 100%;

    .bottom-layer {
      background-color: $septenary-color;
      height: 214px;
      width: 100%;
      z-index: 1;
      position: absolute;
    }

    .mobile-nav-header {
      width: 100%;
      max-width: 100vw;
      position: absolute;
      z-index: 2;

      .mobile-nav-member {
        display: flex;
        align-items: center;
        color: $duodecenary-color;
        margin-block-start: 82px;
        padding-inline: 32px;
        font-family: $font-regular-heading;

        :deep(.card-tier-icon) {
          margin-inline-end: 9px;
        }
      }

      .close-btn-wrapper {
        position: relative;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        max-width: 735px;

        .mobile-nav-close-btn {
          position: absolute;
          margin: 16px;
        }
      }
    }

    .top-layer {
      background-color: $quaternary-color;
      margin-inline-end: 15px;
      height: 100%;
      margin-block-start: 128px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;

      @include md-breakpoint {
        margin-inline-end: 122px;
      }

      .mobile-nav-action {
        font-family: $font-regular-heading;
      }

      .mobile-nav-body {
        padding-inline: 32px;
        padding-block-start: 24px;

        .mobile-nav-links {
          list-style-type: none;
          padding: 0;
          margin: 0;

          .mobile-nav-item {
            &:not(:first-child) {
              margin-block-start: 16px;
            }
          }
        }
      }

      .mobile-nav-footer {
        border-block-start: solid 1px $duonary-color;
        padding-inline: 32px;
        padding-block: 24px 40px;
        margin-block-start: 24px;
      }
    }
  }

  html[dir='rtl'] {
    .mobile-nav {
      transform: translateX(-100%);

      &.show:not(.hiding) {
        transform: none;
      }
    }
  }
</style>
