class CreditCardDetector {
  /**
   * Constructs a CreditCardDetector instance.
   * Initializes an empty array to store registered card schemes.
   */
  constructor() {
    this.registeredSchemes = [];
  }

  /**
   * Adds a card scheme to the registered list, with an optional priority.
   * @param {CreditCardScheme} scheme - The card scheme to add.
   * @param {number} priority - Priority of the scheme (higher values are processed first).
   */
  addScheme(scheme, priority = 0) {
    // Avoiding Duplicate Schemes.
    if (!this.registeredSchemes.some((item) => item.scheme === scheme)) {
      this.registeredSchemes.push({ scheme, priority });

      // Sort the schemes by priority in descending order.
      this.registeredSchemes.sort((a, b) => b.priority - a.priority);
    }
  }

  /**
   * Detects a credit card scheme based on a partial card number. It first checks against
   * registered main schemes. If no match is found, it proceeds to check against any
   * registered sub-schemes of each main scheme. If a match is found in the sub-schemes,
   * the corresponding main scheme is returned.
   *
   * @param {string} partialCardNumber - The partial card number input used for detection.
   * @returns {object|null} - The detected main scheme if a match is found, or null if no match.
   */
  liveDetectScheme(partialCardNumber) {
    for (const { scheme } of this.registeredSchemes) {
      // Main scheme.
      if (scheme.liveDetectScheme(partialCardNumber)) {
        return scheme;
      }

      // Sub schemes.
      if (scheme.getSubSchemes) {
        const matchingSubScheme = scheme.getSubSchemes.find((subScheme) =>
          subScheme.liveDetectScheme(partialCardNumber),
        );

        if (matchingSubScheme) {
          return scheme; // Return main scheme.
        }
      }
    }
    return null;
  }

  /**
   * Detects a credit card scheme based on a full card number. It first checks against
   * registered main schemes. If no match is found, it proceeds to check against any
   * registered sub-schemes of each main scheme. If a match is found in the sub-schemes,
   * the corresponding main scheme is returned. This method ensures that the card number
   * length matches the expected length of the detected scheme.
   *
   * @param {string} fullCardNumber - The full card number input used for detection.
   * @returns {object|null} - The detected main scheme if a match is found, or null if no match.
   */
  detectScheme(fullCardNumber) {
    for (const { scheme } of this.registeredSchemes) {
      // Main scheme.
      if (
        scheme.detectScheme(fullCardNumber) &&
        scheme.cardNumberLength === fullCardNumber.length
      ) {
        return scheme;
      }

      // Sub schemes.
      if (scheme.getSubSchemes) {
        const matchingSubScheme = scheme.getSubSchemes.find(
          (subScheme) =>
            subScheme.detectScheme(fullCardNumber) &&
            subScheme.cardNumberLength === fullCardNumber.length,
        );

        if (matchingSubScheme) {
          return scheme; // Return main scheme.
        }
      }
    }
    return null;
  }

  /**
   * Checks if a partial card number is potentially valid for any registered main scheme
   * or its sub-schemes. This method does not confirm the validity but indicates if the
   * partial number could belong to a known scheme based on its pattern. It first checks
   * against main schemes, and if no potential match is found, it checks against sub-schemes
   * of each main scheme.
   *
   * @param {string} partialCardNumber - The partial card number input used for detection.
   * @returns {boolean} - True if the partial number potentially matches a scheme, false otherwise.
   */
  isPotentiallyValidScheme(partialCardNumber) {
    for (const { scheme } of this.registeredSchemes) {
      // Check main scheme.
      if (scheme.liveDetectScheme(partialCardNumber)) {
        return true;
      }

      // Check sub-schemes.
      if (scheme.getSubSchemes) {
        const hasPotentialMatch = scheme.getSubSchemes.some((subScheme) =>
          subScheme.liveDetectScheme(partialCardNumber),
        );

        if (hasPotentialMatch) {
          return true; // Potentially valid in a sub-scheme.
        }
      }
    }

    return false;
  }

  /**
   * Checks if the provided full card number has a valid length for its detected scheme.
   * @param {string} fullCardNumber - Full card number input.
   * @returns {boolean} - True if the length is valid, otherwise false.
   */
  isValidLength(fullCardNumber) {
    const detectedScheme = this.detectScheme(fullCardNumber);
    return (
      detectedScheme &&
      detectedScheme.cardNumberLength === fullCardNumber.length
    );
  }

  /**
   * Checks if the provided full card number is valid for its detected scheme.
   * @param {string} fullCardNumber - Full card number input.
   * @returns {boolean} - True if the card number is valid, otherwise false.
   */
  isValid(fullCardNumber) {
    const detectedScheme = this.detectScheme(fullCardNumber);
    return (
      detectedScheme &&
      detectedScheme.cardNumberLength === fullCardNumber.length &&
      detectedScheme.isValid(fullCardNumber)
    );
  }

  /**
   * Gets an array of registered scheme names.
   * @returns {string[]} - Array of registered scheme names.
   */
  getRegisteredSchemeNames() {
    return this.registeredSchemes.map(({ scheme }) => scheme.getSchemeName);
  }
}

export default CreditCardDetector;
