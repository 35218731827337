import { defineStore } from 'pinia';
import * as localeConfig from '@/configs/locale-config';

export const useLocaleStore = defineStore('locale', {
  state: () => ({
    locale: null,
    locales: localeConfig.LOCALES,
  }),

  actions: {
    detectBrowserDefaultLocale() {
      // Detect and set browser default locale.
      const browserLanguage =
        window.navigator.language || window.navigator.userLanguage;
      const locale = browserLanguage.replace('-', '_');

      // If browser default locale available make sure it is a valid locale.
      // Return locale.
      return this.getLocales.includes(locale) ? locale : null;
    },

    setLocale(newLocale = null) {
      // Check browserLocale against available locales.
      const browserLocale = this.detectBrowserDefaultLocale();

      // If current locale is not set use browser locale.
      const currentLocale = this.locale || browserLocale;

      // If newLocale is set make sure it is a valid locale.
      this.locale = this.getLocales.includes(newLocale)
        ? newLocale
        : currentLocale;

      // Update index.html language attr
      const localeHTML = this.locale ? this.locale.replace('_', '-') : null;
      document.documentElement.setAttribute('lang', localeHTML);

      // Change direction (LTR, RTL) according to language.
      this.changeDirection();
    },

    redirectToRoute(args = null) {
      this.setLocale(args.params.locale);

      args.params.locale = this.getLocale;

      return args;
    },

    // Change direction (LTR, RTL) according to language.
    changeDirection() {
      this.getLocalesRTL.includes(this.getMainLocale)
        ? this.setDirection('rtl')
        : this.setDirection('ltr');
    },

    // Set direction attr in index.html.
    setDirection(dir) {
      document.documentElement.setAttribute('dir', dir);
    },
  },

  getters: {
    getLocales: (state) =>
      Object.keys(state.locales).flatMap((mainLocale) => {
        const isSubLocales = state.locales[mainLocale].subLocales || [];
        return [mainLocale, ...isSubLocales];
      }),
    getLocalesRTL: (state) =>
      Object.keys(state.locales).filter(
        (key) => state.locales[key].direction === 'rtl',
      ),
    getLocaleFallback: (state) =>
      Object.keys(state.locales).find((key) => state.locales[key].default) ||
      null,
    getLocale: (state) => state.locale || state.getLocaleFallback,
    getMainLocale: (state) => {
      const configLocales = localeConfig.LOCALES;
      for (const mainLocale in configLocales) {
        const subLocales = configLocales[mainLocale].subLocales || [];
        if (mainLocale === state.locale || subLocales.includes(state.locale)) {
          return mainLocale; // Returns main locale if direct match or found in subLocales.
        }
      }

      return state.getLocaleFallback; // Returns fallback locale.
    },
  },

  persist: {
    enabled: true,
    strategies: [
      {
        storage: sessionStorage,
        paths: ['locale'],
      },
    ],
  },
});
