import { createRouter, createWebHistory } from 'vue-guarded-router';
import { useLocaleStore } from '@/stores/LocaleStore';

// Guards
import LocaleGuard from '@/router/guards/locale-guard';
import VerifyTokenGuard from '@/router/guards/verify-token-guard';
import SetBearerTokenGuard from '@/router/guards/set-bearer-token-guard';
import MaxCardsReachedGuard from '@/router/guards/max-cards-reached-guard';
import HasValidEmailGuard from '@/router/guards/has-valid-email-guard';
import ResetUnauthorizedErrorFlag from '@/router/guards/reset-unauthorized-error-flag-guard';
import AuthGuard from '@/router/guards/auth-guard';
import PreviousRouteGuard from '@/router/guards/previous-route-guard';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  globalNavigationGuards: {
    beforeEach: [
      PreviousRouteGuard,
      LocaleGuard,
      SetBearerTokenGuard,
      AuthGuard,
      VerifyTokenGuard,
    ],
  },
  routes: [
    {
      path: '/',
      redirect: (to) => {
        const localeStore = useLocaleStore();
        return localeStore.redirectToRoute({
          name: 'AuthenticationView',
          params: { locale: to.params.locale, ...to.params },
          query: to.query,
        });
      },
    },
    {
      path: '/:locale',
      redirect: { name: 'ManageCards' },
      children: [
        {
          path: '',
          name: 'AuthenticationView',
          component: () => import('@/views/AuthenticationView.vue'),
        },
        {
          path: 'access-denied',
          name: 'AccessDenied',
          component: () => import('@/views/AccessDeniedView.vue'),
          navigationGuards: {
            beforeEnter: [ResetUnauthorizedErrorFlag],
          },
          meta: { title: 'route_title_access_denied' },
        },
        {
          path: 'manage-cards',
          name: 'ManageCards',
          component: () => import('@/views/ManageCardsView.vue'),
          meta: {
            requiresAuth: true,
            title: 'route_title_manage_cards',
          },
        },
        {
          path: 'link-card',
          name: 'LinkCard',
          component: () => import('@/views/LinkCardView.vue'),
          meta: {
            requiresAuth: true,
            title: 'route_title_link_card',
          },
          navigationGuards: {
            beforeEnter: [MaxCardsReachedGuard, HasValidEmailGuard],
          },
        },
        {
          path: 'verify-token',
          name: 'VerifyToken',
          meta: {
            title: 'route_title_verify_token',
          },
        },
        {
          path: '404',
          name: 'PageNotFound',
          component: () => import('@/views/PageNotFoundView.vue'),
          meta: { title: 'route_title_page_not_found' },
        },
      ],
    },
    {
      path: '/:locale/:pathMatch(.*)*',
      redirect: (to) => {
        const localeStore = useLocaleStore();

        return localeStore.redirectToRoute({
          name: 'PageNotFound',
          params: { locale: to.params.locale },
        });
      },
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
