<script setup>
  import { computed, ref, useSlots } from 'vue';
  import { vMaska } from 'maska';

  const slots = useSlots();
  const emit = defineEmits(['update:modelValue']);
  const props = defineProps({
    modelValue: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    inputStateError: {
      type: Boolean,
      default: false,
    },
    inputStateSuccess: {
      type: Boolean,
      default: false,
    },
    stateMsg: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: String,
      default: '',
    },
    dataMaska: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    inputmode: {
      type: String,
      default: 'text',
      validator: (value) => {
        return (
          [
            'text',
            'decimal',
            'numeric',
            'tel',
            'search',
            'email',
            'url',
          ].indexOf(value) !== -1
        );
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ariaDisabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  });

  const baseInput = ref(null);

  const isInputFilled = computed(() =>
    props.modelValue ? 'input-filled' : '',
  );

  /**
   * Input has a state.
   * @type {ComputedRef<boolean>}
   */
  const hasState = computed(() => {
    return props.inputStateError || props.inputStateSuccess;
  });

  /**
   * Input State type.
   * @type {ComputedRef<null|string>}
   */
  const stateType = computed(() => {
    return !hasState.value
      ? null
      : props.inputStateSuccess
        ? 'valid'
        : 'invalid';
  });

  /**
   * Input feedback, input class, message class).
   * @type {ComputedRef<{stateMessage: string, stateInput: string}|{stateMessage: null, stateInput: null}>}
   */
  const feedback = computed(() => {
    return hasState.value
      ? {
          stateInput: `is-${stateType.value}`,
          stateMessage: `${stateType.value}-feedback`,
        }
      : { stateInput: null, stateMessage: null };
  });

  const isDisabled = computed(() => props.disabled);
  const isAriaDisabled = computed(() => props.ariaDisabled || props.disabled);
  const isReadonly = computed(() => props.readonly || props.ariaDisabled);

  const isAriaDisabledInput = computed(() =>
    props.ariaDisabled ? 'aria-disabled-input' : null,
  );

  const modelValueInput = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });

  function focusOnInput() {
    baseInput.value.focus();
  }
</script>

<template>
  <div class="form-floating base-floating-input">
    <label class="visually-hidden" aria-hidden="true" :for="id">
      {{ label }}
    </label>
    <input
      ref="baseInput"
      v-model="modelValueInput"
      class="form-control base-input"
      :class="[feedback.stateInput, isInputFilled, isAriaDisabledInput]"
      :type="type"
      :id="id"
      :name="id"
      :aria-label="label"
      v-maska
      :data-maska="dataMaska"
      :required="required"
      :aria-required="required"
      :maxlength="maxLength"
      :autocomplete="autocomplete"
      :inputmode="inputmode"
      :disabled="isDisabled"
      :aria-disabled="isAriaDisabled"
      :readonly="isReadonly"
      :aria-readonly="readonly"
    />
    <span class="base-label" aria-hidden="true">{{ label }}</span>

    <div
      v-if="slots.icon"
      aria-hidden="true"
      class="icon-wrapper"
      @click="focusOnInput"
    >
      <slot name="icon"></slot>
    </div>

    <text-assistive
      :value="stateMsg"
      v-show="stateMsg && hasState"
      :hidden="false"
    >
      <template v-slot="{ textToRead }">
        <p
          :class="[feedback.stateMessage]"
          class="base-input-feedback"
          v-for="(text, index) in textToRead"
          :key="index"
        >
          <icon-error v-if="inputStateError" />
          {{ text }}
        </p>
      </template>
    </text-assistive>
  </div>
</template>

<style lang="scss" scoped>
  .base-floating-input {
    max-width: 704px;
    width: 100%;
    margin-block-end: 16px;

    @include md-breakpoint {
      max-width: 556px;
    }

    .base-input {
      min-height: 60px;
      border-radius: 0;
      border-color: $border-color;
      padding-inline: 24px;
      font-family: $font-regular-text;
      font-size: 1em;
      line-height: 24px;
      color: $duodecenary-color;

      &:focus ~ .base-label,
      &.input-filled ~ .base-label {
        color: $senary-color;
        font-size: 0.75em;
        line-height: 16px;
        transform: scale(0.9) translateY(-0.5rem) translateX(0);
        transition: transform 0.1s ease-in-out;
        top: 0;
        left: 3px;

        &:after {
          background-color: transparent;
        }
      }

      &:disabled,
      &.aria-disabled-input {
        color: $senary-color;
        background-color: $duonary-color;
        opacity: 1;
        pointer-events: none;

        ~ .icon-wrapper {
          cursor: auto;
        }

        ~ .base-label {
          color: $senary-color;
        }

        &:focus {
          border-color: $denary-color;
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);

          &.input-filled ~ .base-label {
            transform: scale(0.9) translateY(-0.5rem) translateX(0rem);
          }

          &:not(.input-filled) ~ .base-label {
            opacity: 1;
            transform: scale(1) translateY(0rem) translateX(0rem);
          }
        }

        &:hover,
        &:active {
          border-color: $denary-color;
        }
      }

      &.is-invalid,
      &.is-valid {
        background-image: none;
      }

      &.is-invalid {
        border-color: $tertiary-color;
      }
    }

    .base-label {
      padding-inline: 24px;
      padding-block: 18px;
      width: 80%;
      font-family: $font-regular-text;
      font-size: 1em;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 49;
      height: 100%;
      overflow: hidden;
      text-align: start;
      text-overflow: ellipsis;
      white-space: nowrap;
      pointer-events: none;
      transform-origin: 0 0;
      transition:
        opacity 0.1s ease-in-out,
        transform 0.1s ease-in-out;
      cursor: pointer;
    }

    .base-input-feedback {
      font-size: 0.75em;
      line-height: 16px;
      font-family: $font-regular-text;
      margin-block-end: 0;

      &.invalid-feedback {
        color: $tertiary-color;
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .icon-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      width: max-content;
      min-inline-size: 8px;
      padding-block: 16px;
      padding-inline: 24px;
      display: flex;
      justify-content: flex-end;
      cursor: text;
    }
  }

  html[dir='rtl'] {
    .base-floating-input {
      .base-input {
        &:focus ~ .base-label,
        &.input-filled ~ .base-label {
          transform: scale(0.9) translateY(-0.5rem) translateX(-0.21rem);
        }
      }

      .base-label {
        top: 0;
        right: 0;
        transform-origin: 100% 0;
        transition:
          opacity 0.1s ease-in-out,
          transform 0.1s ease-in-out;
      }

      .icon-wrapper {
        left: 0;
        right: unset;
      }
    }
  }
</style>
