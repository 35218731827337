import { resolveDynamicComponent } from 'vue';
import { useLocaleStore } from '@/stores/LocaleStore';

export function useUtility() {
  // Transforms a hyphen-separated string into PascalCase format. For example, "visa-vop" becomes "VisaVop".
  function convertToPascalCase(string) {
    return (string || '')
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join('');
  }

  // Resolves and returns a dynamic component if `value` is valid, otherwise returns null.
  function getDynamicComponent(value) {
    const dynamicComponent = resolveDynamicComponent(value);
    return value && typeof dynamicComponent !== 'string'
      ? dynamicComponent
      : null;
  }

  function getLocaleBasedUrl(url, replacementKey = 'locale') {
    const localeStore = useLocaleStore();
    return url.replace(
      new RegExp(`\\[${replacementKey}\\]`, 'g'),
      localeStore.getLocale,
    );
  }

  return {
    convertToPascalCase,
    getDynamicComponent,
    getLocaleBasedUrl,
  };
}
